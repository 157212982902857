import { useNavigate } from 'react-router-dom';
import { getPermissionObject } from '../../helper/Utility.helper';
// @mui
import {
    Box, Typography, Grid, Stack, Paper, Avatar, Button
} from '@mui/material';
// Custom Components
import CreativeIcon1 from '../../assets/Creative team-bro-amber.svg';
import { Item, GradientTypography } from './WelcomeCardStyle'

const WelcomeCard = () => {
    const navigate = useNavigate();
    const permissionObject = getPermissionObject();
    const devicesAddPermission = permissionObject.devices.create;
    return (
        <Paper elevation={4}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
            >
                <Grid item md={6}>
                    <Box sx={{ p: 5, maxHeight: "300px" }}>
                        <Stack
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Item>
                                <GradientTypography variant="h5">Welcome to Atra</GradientTypography>
                            </Item>
                            <Item>
                                <Typography variant="body1" sx={{ color: '#707070' }}>A device and remote management platform, get started by adding device to platform.</Typography>
                            </Item>
                            {devicesAddPermission ?
                                <Item>
                                    <Button
                                        variant='contained'
                                        color='warning'
                                        size='small'
                                        onClick={() => navigate('/devices/add-devices', { replace: true })}
                                    >Add Device</Button>
                                </Item>
                                : null}
                        </Stack>
                    </Box>
                </Grid>
                <Grid item md={6}>
                    <Avatar
                        variant="rounded"
                        src={CreativeIcon1}
                        sx={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '280px',
                            // objectFit: 'cover' 
                            // position: 'absolute',
                        }}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
};
export default WelcomeCard;