import _ from "lodash";
import dayjs from "dayjs";
import {
    Grid, Card, CardHeader, CardContent,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from "@mui/material";
import { BoxItem } from "../../components/Common/Item.component";
export default function RecentOrganizationList({ data }) {

    return (
        <Grid item >
            <Card elevation={0} sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '15px' }}>
                <CardHeader
                    title="Recently Added Child Organizations"
                />
                <CardContent sx={{ pt: 0, flexGrow: 1 }}>
                    <BoxItem sx={{ textAlign: 'left', pt: 0, backgroundColor: 'inherit' }}>
                        <TableContainer sx={{ borderRadius: '15px' }}>
                            <Table size="small" aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Created On</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {data.map((obj) => (
                                        <TableRow
                                            key={obj._docId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">{_.toUpper(obj.name)}</TableCell>
                                            <TableCell component="th" scope="row">{dayjs(obj.createdOn).format('lll')}</TableCell>
                                        </TableRow>

                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BoxItem>
                </CardContent>
            </Card>
        </Grid>
    )
}