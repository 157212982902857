import _ from "lodash";
import Avatar from "@mui/material/Avatar";
import { stringToColor } from "../../helper/Utility.helper";
const stringAvatar = (name) => {
    const size = 36;
    const wordArray = name.split(' ');
    const dpLetters = wordArray.length === 1 ? wordArray[0][0] : `${wordArray[0][0]}${wordArray[1][0]}`;
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: size,
            height: size
        },
        children: dpLetters,
        variant: 'circular'
    }
}

const AccountAvatar = ({ user }) => {
    const name = _.isEmpty(user) ? "Zombie" : _.toUpper(user.name);
    return <Avatar {...stringAvatar(name)} />;
}
export default AccountAvatar;
