import _ from "lodash"
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
// Icons
import ConnectedDeviceIcon from '../../assets/Signals.gif';

const MapLocation = ({ data }) => {

    const customConnectedIcon = new L.Icon({
        iconUrl: ConnectedDeviceIcon,
        iconSize: [30, 40.5], // size of the icon
        iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor
    });

    return (
        <MapContainer center={[23.033664, 72.524938]} zoom={17} style={{ height: '400px', borderRadius: '15px' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='Atreyo R And D LLp'
            />
            {!_.isEmpty(data) ?
                data.map((obj) => (

                    <Marker position={[obj.coords[0], obj.coords[1]]} icon={customConnectedIcon} key={obj._docId}>
                        <Tooltip direction='top' offset={[-1, -30]}>
                            {obj.serial}
                        </Tooltip>
                    </Marker>
                )) : null}
        </MapContainer>
    );
};

export default MapLocation;