import { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { Typography } from "@mui/material";

const DeviceCountByOrganizationTreeChart = () => {
    const [loading, setLoading] = useState(false);
    const [isData, setIsData] = useState(false);
    const [dataLength, setDataLength] = useState(null);
    const user = useSelector(state => state.authenticate.user);


    const [state, setState] = useState({
        series: [
            {
                data: [
                    {
                        x: '',
                        y: 0
                    }
                ]
            }
        ],
        options: {
            legend: {
                show: false
            },
            chart: {
                height: 350,
                type: 'treemap'
            },

        },
    });

    const getData = async () => {
        setLoading(true);
        axios.get('/api/overview/devicecountsbyorganizations')
            .then(response => {
                const data = response.data;
                if (data.status) {
                    setIsData(true);


                    const newOptions = {
                        ...state,
                        series: [{
                            data: _.map(data.data, obj => ({ x: obj.organizationName.toUpperCase(), y: obj.deviceCounts }))
                        }]
                    }
                    setState(newOptions);
                    setDataLength(data)
                }
                setLoading(false);
            }).catch(error => {
                console.log('An error occurred while fetching chart data', error);
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (user.level !== 3) {
            getData();
        }
    }, []);

    return (
        <div id="chart">
            {_.has(dataLength, 'data') && !_.isEmpty(dataLength.data) ?
                <ReactApexChart options={state.options} series={state.series} type="treemap" height={350} />
                : <Typography sx={{ ml: 3 }}>Data Not Available</Typography>}
        </div>
    );
}

export default DeviceCountByOrganizationTreeChart;