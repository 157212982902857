import { useState, useEffect } from 'react';
import '../../components/Card/StatusCardGrid.css'
import '../../components/Card/ModelCardGrid.css'
import { useSelector } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
// @mui
import { Box, Paper, Grid, CardHeader } from '@mui/material';

// MUI icons
import ErrorIcon from '@mui/icons-material/Error';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import BusinessIcon from '@mui/icons-material/Business';
import { RiRouterFill } from 'react-icons/ri';
// Custom components
import DeviceStatusCard from '../../layouts/Overview/DeviceStatusCard.layout';
import WelcomeCard from '../../layouts/Overview/WelcomeCard.layout';
import CountByModelBarChart from '../../sections/overview/CountByModelBarChart.section';
import OnlineStatusPieChart from '../../sections/overview/OnlineStatusPieChart.section';
import OfflineStatusPieChart from '../../sections/overview/OfflineStatusPieChart.section';
import RecentDeviceList from '../../layouts/Overview/RecentDeviceList.layout';
import RecentOrganizationList from '../../layouts/Overview/RecentOrganizationList.layout';
import DeviceCountByOrganizationTreeChart from './DeviceCountByOrganizationTreeChart.layout';
import MapLocation from '../../layouts/Overview/MapLocation.layout';

const getValueByKey = (d, k) => !_.isEmpty(d) && _.has(d, k) ? d[k] : '-';

export default function OverviewSection() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [devicesLocation, setDevicesLocation] = useState(null);
    const [recentlyaddedDevices, setRecentlyaddedDevices] = useState([]);
    const [recentlyaddedOrganizations, setRecentlyaddedOrganizations] = useState([]);
    const user = useSelector(state => state.authenticate.user);


    const getData = async () => {
        const response = await axios.get('api/overview/counts');
        const result = await response.data;
        setData(result.data);
    }

    const getDevicesLocation = async () => {
        setLoading(true);
        const response = await axios.get('/api/overview/devicecoords');
        const data = await response.data;
        setDevicesLocation(data.data);
        setLoading(false);
    }

    const getRecentlyaddedDevices = async () => {
        setLoading(true);
        const response = await axios.get('/api/overview/recentlyaddeddevices');
        const data = await response.data;
        setRecentlyaddedDevices(data.data);
        setLoading(false);
    }

    const getRecentlyaddedOrganizations = async () => {
        setLoading(true);
        const response = await axios.get('/api/overview/recentlyaddedchildorganizations');
        const data = await response.data;
        setRecentlyaddedOrganizations(data.data);
        setLoading(false);
    }

    useEffect(() => {
        getData();
        getDevicesLocation();
        getRecentlyaddedDevices();
        if (user.level !== 3) {
            getRecentlyaddedOrganizations();
        }
    }, []);

    return (
        <>
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <div className="grid-container">
                    <div className="item item1">
                        <DeviceStatusCard
                            icon={<LightbulbIcon sx={{ fontSize: 36 }} />}
                            bgcolor={{ bgcolor: '#28B25F' }}
                            count={getValueByKey(data, 'online')}
                            title={'Online'}
                        />
                    </div>
                    <div className="item item2">
                        <DeviceStatusCard
                            icon={<RiRouterFill size='36px' />}
                            bgcolor={{ bgcolor: '#A333C8' }}
                            count={getValueByKey(data, 'totalDevices')}
                            title={'All devices'}
                        />
                    </div>
                    <div className="item item3">
                        <DeviceStatusCard
                            icon={<ErrorIcon sx={{ fontSize: 36 }} />}
                            bgcolor={{ bgcolor: '#DB445F' }}
                            count={getValueByKey(data, 'offline')}
                            title={'Offline'}
                        />
                    </div>
                    <div className="item item4">
                        <DeviceStatusCard
                            icon={<BusinessIcon sx={{ fontSize: 36 }} />}
                            bgcolor={{ bgcolor: '#D9B03B' }}
                            count={getValueByKey(data, 'childCompanies')}
                            title={'Child Organizations'}
                        />
                    </div>
                    <div className="item item5">
                        <WelcomeCard />
                    </div>
                </div>
            </Box>
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <div className="model-card-grid-container">
                    <div className="model-card model-card1">
                        <Paper elevation={4} sx={{ width: '-webkit-fill-available', p: 2 }}>
                            <CardHeader
                                title={"Online Devices"} sx={{ mb: 5, textAlign: 'left' }}
                            />
                            <OnlineStatusPieChart />
                        </Paper>
                    </div>
                    <div className="model-card model-card2">
                        <Paper elevation={4} sx={{ width: '-webkit-fill-available', p: 2 }}>
                            <CardHeader
                                title={"Offline Devices"} sx={{ mb: 5, textAlign: 'left' }}
                            />
                            <OfflineStatusPieChart />
                        </Paper>
                    </div>

                    <div className="model-card model-card3">
                        <Paper elevation={4} sx={{ p: 2, height: '96.2%' }} >
                            <CardHeader
                                title={"All Devices"} sx={{ mb: 5, textAlign: 'left' }}
                            />
                            <CountByModelBarChart />
                        </Paper>
                    </div>
                </div>
            </Box>

            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Paper elevation={4} sx={{ p: 4, width: '-webkit-fill-available' }}>
                        <CardHeader
                            title={"Device Locations"} sx={{ mb: 5, textAlign: 'left' }}
                        />
                        <MapLocation data={devicesLocation} />
                    </Paper>
                </Grid>

                {!_.isEmpty(recentlyaddedDevices) ?
                    <Grid item xs={12}>
                        <Paper elevation={4} sx={{ width: '-webkit-fill-available' }}>
                            <RecentDeviceList data={recentlyaddedDevices} />
                        </Paper>
                    </Grid>
                    : null}

                {!_.isEmpty(recentlyaddedOrganizations) ?
                    <Grid item xs={6}>
                        <Paper elevation={4} sx={{ width: '-webkit-fill-available' }}>
                            <RecentOrganizationList data={recentlyaddedOrganizations} />
                        </Paper>
                    </Grid>
                    : null}

                <Grid item xs={6}>
                    <Paper elevation={4} sx={{ p: 2 }} >
                        <CardHeader
                            title={"Device Count by Organization "} sx={{ textAlign: 'left' }}
                        />
                        <DeviceCountByOrganizationTreeChart />
                    </Paper>
                </Grid>

            </Grid>
        </>
    )
}