import React, { Component } from "react";
import Chart from "react-apexcharts";

class Radialbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [this.props.cpu || this.props.ram || this.props.internalStorage],
            options: {
                chart: {
                    height: this.props.height,
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: '70%',
                        }
                    },
                },
                labels: [this.props.cpu ? "CPU" : "-" && this.props.ram ? "RAM" : '-' && this.props.internalStorage ? "Storage" : "-"],
            },


        };
    }

    render() {
        return (
            <div style={{ width: 160 }}>
                <div id="chart">
                    <Chart options={this.state.options} series={this.state.series} type="radialBar" height={this.props.height} />
                </div>
            </div>
        );
    }
}


export default Radialbar;