import { useNavigate } from "react-router-dom";

import {
    Box, Dialog, DialogTitle, DialogContent, FormControlLabel, Container,
    DialogActions, FormControl, RadioGroup, Radio, Button, Stack,
    Typography, Snackbar, Alert
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from 'axios';
import _ from 'lodash'
import { useFormik } from "formik";
import * as Yup from 'yup';
import Loading from "../../components/Common/Loading.Component";

export function ChangeUserRoleDialog({ openDialog, handleClose, roleId, id, refresh }) {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const handleCloseSnackbar = () => {
        setOpen(false);
    }

    const getData = async () => {
        setLoading(true);
        const response = await axios.get("api/roles");
        const result = await response.data;
        setData(result.data);
        setLoading(false);
    }

    const initialValues = {
        roleId: roleId,
        userId: id
    }

    const validationSchema = Yup.object().shape({
        roleId: Yup.string()
            .ensure().uuid('Invalid role')
            .required('Role is required'),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // console.log(JSON.stringify(values, null, 2))
            putData(values);
        },
        onReset: () => { }
    });

    const putData = async (data) => {
        const response = await axios.put('api/users/changerole', data);
        const result = await response.data;
        handleClose();
        setOpen(true);
        setMessage(result);
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (!_.isEmpty(message) && !open) refresh();
    }, [message, open]);

    return (
        <Box>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
            >
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Change Role</DialogTitle>
                    <DialogContent>
                        <Container maxWidth="sm">
                            <Stack
                                sx={{ mt: 1 }}
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                spacing={3}
                            >
                                <Box>
                                    <FormControl fullWidth>
                                        <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
                                            {loading ? <Loading /> :
                                                <RadioGroup
                                                    id="roleId"
                                                    name="roleId"
                                                    value={formik.values.roleId}
                                                    onChange={formik.handleChange}
                                                >
                                                    {
                                                        data.length === 0 ?
                                                            <Typography>There are no records to display</Typography> :
                                                            data.map(obj => (<FormControlLabel key={obj._docId} value={obj._docId} control={<Radio />} label={_.toUpper(obj.name)} />))
                                                    }
                                                </RadioGroup>
                                            }
                                        </Box>
                                    </FormControl>
                                </Box>
                            </Stack>
                        </Container>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">Cancel</Button>
                        <Button
                            type="submit"
                            variant="contained"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleCloseSnackbar}
                autoHideDuration={2250}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleCloseSnackbar}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    );
}