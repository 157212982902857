// MUI Components
import { Grid, Button, IconButton, } from "@mui/material";

// Material Icons

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SyncIcon from '@mui/icons-material/Sync';

// Custom Components
import Search from "../../components/Search/Search.component";

const actions = ({ handleNavigate, handleQueryInput, getData }) => (
    <Grid container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-end"
    // spacing={1}
    >

        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id="distributorSearch" handleChange={handleQueryInput} placeholder="Name..." />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button disabled variant="contained" size="small" sx={{ float: 'right' }} endIcon={<FilterAltIcon />}>Filter</Button>
        </Grid>
    </Grid>
);

const AddVPNTunnel = ({ handleNavigate }) => (<Button
    variant="contained"
    size="small"
    startIcon={<AddRoundedIcon />}
    onClick={handleNavigate}
>Create</Button>)

export { AddVPNTunnel, actions }