import { Routes, Route } from 'react-router-dom'
import { getPermissionObject } from "../../helper/Utility.helper";
import UserList from '../../sections/Users/UsersList.section';
import InviteUser from '../../sections/Users/InviteUser.section';
const UserPage = () => {

    //permission decoding
    const permissionObject = getPermissionObject();
    const usersAddPermission = permissionObject.users.create;

    return (
        <Routes>
            <Route path='/' element={<UserList />} />
            <Route path='/invite-user' element={usersAddPermission ? <InviteUser /> : null} />
        </Routes>
    );
}

export default UserPage;