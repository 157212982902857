// axios.interceptor.js
import axios from "axios";
import { logoutUser } from "../services/login.service";
// import { userPool } from "../services/auth.service";
import { getCurrentUser } from "./Utility.helper";
import _ from "lodash";
// axios.defaults.baseURL = 'https://ntk4ooylm4.execute-api.ap-south-1.amazonaws.com/DeployAPI/';
// axios.defaults.baseURL = 'http://localhost:5001/';
// axios.defaults.baseURL = 'http://192.168.10.10:5001';//use for development
axios.defaults.baseURL = 'https://iwsmq9rwku.ap-south-1.awsapprunner.com';//use for production


const interceptor = (store) => {
    // const dispatch = useDispatch();
    axios.interceptors.request.use(
        config => {
            // const currentUser = userPool.getCurrentUser();
            // if (currentUser) {
            // currentUser.getSession((err, sess) => {
            //     if (err) return Promise.reject(err);
            //     const token = sess['idToken']['jwtToken'];
            //     // if (token) 
            //     config.headers.Authorization = `${token}`;
            // })

            // }
            // console.log('intercepter config:', config);
            const user = getCurrentUser();
            if (user) {
                const token = localStorage.getItem('_aid');
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 401 && _.has(error, 'response.data.code') && error.response.data.code === "TOKEN_EXPIRED") {
                console.error('Interceptor Error', error);
                store.dispatch(logoutUser());
            }
            return Promise.reject(error);
        }
    );
}
// export default axios;
export default interceptor;