import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

//Mui
import { Box, Stack, Paper, FormControl, Button, TextField, Snackbar, Alert } from "@mui/material";

//Custom Component
import { BoxItem } from "../../components/Common/Item.component";
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";

export default function CreateOrganization() {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [organizationName, setOrganizationName] = useState('')
    const [nameError, setNameError] = useState('');

    const handleOrganizationNameChange = (event) => {
        setNameError('');
        setOrganizationName(event.target.value);
    }

    const handleReset = () => {
        setOrganizationName('');
        setNameError('');
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const regex = /^[a-zA-Z ]{2,30}$/;
        // Handle form submission logic here
        if (organizationName === null || organizationName === undefined || organizationName === "") {
            setNameError('Name required');
            return;
        }

        if (!regex.test(organizationName)) {
            setNameError('Not valid');
            return;
        }

        setNameError('');

        const formData = {
            name: organizationName
        }
        // alert(JSON.stringify(formData));
        postData(formData);
        setOrganizationName('');
    };

    const handleClose = () => {
        setOpen(false);
        navigate('/organizations', { replace: true });
    }

    const postData = async (data) => {
        const response = await axios.post('api/organization', data);
        const result = await response.data;
        // console.log('Install response', response.status);
        setOpen(true);
        setMessage(result);
        if (result.status) {
            setTimeout(() => {
                navigate('/organization', { replace: true });
            }, 4000);
        }
    }

    return (

        <Box sx={{ width: "screen" }}>
            <SectionHeader heading="Create New Organization" />
            <Box sx={{ flexGrow: 1, mt: 4 }}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}>
                    <Paper elevation={4} sx={{ borderRadius: '15px', padding: 4, width: 450 }}>
                        <BoxItem>
                            <Box
                                noValidate
                                component="form"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    m: 'auto',
                                    // width: 'fit-content',
                                }}>
                                <FormControl sx={{ mb: 4, minWidth: 350 }} size="small">
                                    <TextField
                                        size="small"
                                        name="organizationName"
                                        id="organization-Name"
                                        label="Organization Name"
                                        variant="outlined"
                                        value={organizationName}
                                        fullWidth
                                        required
                                        onChange={handleOrganizationNameChange}
                                        error={(nameError !== "") ? true : false}
                                        helperText={(nameError !== "") ? nameError : null}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="outlined" onClick={handleReset}>Reset</Button>
                                <Button variant="contained" type="submit" onClick={handleSubmit}>Create</Button>
                                <Button variant="outlined" color="error" onClick={handleClose}> Cancel </Button>
                            </Box>
                        </BoxItem>
                    </Paper>
                </Stack>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleClose}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    );
}