import { useState } from 'react';
import axios from "axios";
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    Box,
    Grid,
    TextField,
    Typography,
    Link, FormControl,
    OutlinedInput,
    InputAdornment,
    IconButton,
    InputLabel,
    Alert, FormHelperText, Snackbar
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';
import Copyright from '../../layouts/Authentication/Copyright.component';
import logo from '../../assets/ATRA_emblem.png';
import backgroundImage from '../../assets/sideBanner.jpg';
import { getDefaultTimezone } from "../../helper/Utility.helper";
const BackgroundContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    display: 'block',
    overflow: 'hidden',
}));

const FormContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ImageContainer = styled('div')(({ theme }) => ({
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'left',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Logo = styled('img')(({ theme }) => ({
    width: '6em',
    marginBottom: theme.spacing(3),
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
    background: `-webkit-linear-gradient(-65deg, #ABD9E6 5%, #48A89D 27%, #134E5E 95%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    paddingBottom: theme.spacing(6),
}));

export default function Invite() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const error = useSelector(state => state.authenticate.error);
    // const isLoading = useSelector(state => state.authenticate.loading);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const handleClose = () => {
        setOpen(false);
    }

    // const postData = async (data) => {
    //     axios.post(`/auth/invite/${searchParams.get('token')}`, data).then(res => {
    //         console.log('request status:', res.status);
    //         console.log('response data', res.data);
    //         setOpen(true);
    //         setMessage(result);
    //     }).catch(err => {
    //         console.error('An error occurred:', err.message);
    //     })
    // }

    const postData = async (data) => {
        const response = await axios.post(`/auth/invite/${searchParams.get('token')}`, data);
        const result = await response.data;
        // console.log('Install response', response.status);
        setOpen(true);
        setMessage(result);
        if (result.status) {
            setTimeout(() => {
                navigate('/login', { replace: true });
            }, 2000);
        }
    }


    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
                .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
                .matches(/[0-9]/, 'Password must contain at least one numeric character')
                .matches(/^(?![-])(?!(.*[()])).*[^a-zA-Z0-9]/, 'Password must contain at least one special character')
                .required('Password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is required')
        }),
        onSubmit: (formData) => {
            setIsLoading(true);
            const encoded = btoa(formData.password)
            postData({
                "password": encoded,
                "zoneInfo": getDefaultTimezone()
            })
            setTimeout(() => {
                setIsLoading(false);
            }, 4000)
        }
    });

    return (
        <>
            <BackgroundContainer>
                <Grid container >
                    <Grid item xs={12} md={12} lg={5} xl={5}>
                        <form onSubmit={formik.handleSubmit}>
                            <FormContainer>
                                <Logo src={logo} alt="Logo" />
                                <GradientTypography component="div" variant="h5" align="center">
                                    {/* {'प्रवेशः।'} */}
                                    {`Account Setup`}
                                </GradientTypography>

                                <Box sx={{ width: '50%' }}>
                                    <Box sx={{ mb: 3 }}>
                                        <FormControl
                                            variant="outlined"
                                            sx={{ width: '100%' }}
                                            error={formik.touched.password && Boolean(formik.errors.password)} // Move error to FormControl
                                        >
                                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                size="small"
                                                sx={{ mt: 1 }}
                                                id="password"
                                                label="Password"
                                                name="password"
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={showPassword ? 'hide the password' : 'display the password'}
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            onMouseUp={handleMouseUpPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {formik.touched.password && formik.errors.password && (
                                                <FormHelperText>{formik.errors.password}</FormHelperText> // Add helper text here
                                            )}
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ mb: 3 }}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            type="password"
                                            label="Confirm Password"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            value={formik.values.confirmPassword}
                                            onChange={formik.handleChange}
                                            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ mt: 0, width: '50%' }}>
                                    <LoadingButton
                                        variant="contained"
                                        size="small"
                                        type="submit"
                                        sx={{
                                            mt: 4,
                                            width: '100%'
                                        }}
                                        endIcon={<LoginIcon />}
                                        loading={isLoading}
                                    >
                                        Create Account
                                    </LoadingButton >
                                </Box>

                                <Grid container justifyContent="center" sx={{ mt: 2 }}>
                                    <Grid item>
                                        <Typography variant="body2" color="textSecondary" sx={{ cursor: 'pointer' }}>
                                            {/* {`Already have an account? `} */}

                                            <Link onClick={() => navigate('/login', { replace: true })}>Sign In</Link>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Copyright
                                    sx={{ mt: 2 }}
                                />

                                {
                                    (error && error.message !== "") ?
                                        <Grid container justifyContent="center" sx={{ mt: 2 }}>
                                            <Grid item>
                                                <Alert severity="error">
                                                    {error.message}
                                                </Alert>
                                            </Grid>
                                        </Grid>
                                        : null
                                }
                            </FormContainer>
                        </form>
                    </Grid>
                    <Grid item xs={12} md={7} sx={{ padding: 0, margin: '0px' }}>
                        <ImageContainer />
                    </Grid>
                </Grid>
            </BackgroundContainer>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleClose}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </>
    );
};