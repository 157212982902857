import { IconButton, Grid, Button } from "@mui/material";
import SyncIcon from '@mui/icons-material/Sync';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Search from "../../components/Search/Search.component";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteIcon from "@mui/icons-material/Delete";

const actions = ({ handleNavigate, handleQueryInput, getData }) => (
    <Grid container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-end"
    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id="distributorSearch" handleChange={handleQueryInput} placeholder="Name..." />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button disabled variant="contained" size="small" sx={{ float: 'right' }} endIcon={<FilterAltIcon />}>Filter</Button>
        </Grid>
    </Grid>
);

const Create = ({ handleNavigate }) => (
    <Button
        variant="contained"
        size="small"
        startIcon={<AddRoundedIcon />}
        onClick={handleNavigate}
    >Create</Button>
)

const contextActions = deleteHandler => (
    <IconButton onClick={deleteHandler}>
        <DeleteIcon />
    </IconButton>
);

export { actions, Create, contextActions };