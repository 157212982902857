import { styled } from '@mui/material/styles';

//Device Media Card Style 

const StyledCardMedia = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)',
    // backgroundColor: '#48a89d',
    // backgroundImage: 'linear-gradient(to bottom left, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)',
    // '&::before': {
    //     top: 0,
    //     left: 0,
    //     width: '100%',
    //     height: '100%',
    //     backgroundImage: 'linear-gradient(to right, rgba(19, 78, 94, 0.88)  27%, rgba(72, 168, 157, 0.7) 83%, rgba(171, 217, 230, 0.8) 10%)',
    //     // opacity: 1,
    // }

    // borderBottom: '1px solid #ECEFF1'
})

const StyledCover = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',

});

export { StyledCover, StyledCardMedia }