import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from 'axios';
import _ from "lodash";

// MUI Components
import { Box, Grid, Snackbar, Alert } from "@mui/material";

//Custom Components
import VPNTunnelBasicInfoCard from "../../layouts/VPNTunnelDetail/VPNTunnelBasicInfoCard.layout";
import VPNTunnelDeviceListCard from "../../layouts/VPNTunnelDetail/VPNTunnelDeviceListCard.layout";
import VPNTunnelUserListCard from "../../layouts/VPNTunnelDetail/VPNTunnelUserListCard.layout";
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import Loading from "../../components/Common/Loading.Component";
import ConfirmDelete from "../../layouts/Delete.layout";


export default function VPNProfile({ id }) {

    const { _docId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [confirmDeleteDialogState, setConfirmDeleteDialogState] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const handleConfirmDeleteDialogOpen = (id) => {
        setConfirmDeleteDialogState(true);
        setDeleteId(id);
    }

    const handleConfirmDeleteDialogClose = () => setConfirmDeleteDialogState(false);

    const handleSnakbarClose = () => setSnackbarOpen(false);

    const getData = async () => {
        setLoading(true);
        const response = await axios.get(`api/vpn/${_docId} `);
        const result = await response.data;
        setProfile(result.data);
        setLoading(false);
    }

    const deleteData = async () => {
        const response = await axios.delete(`/api/vpn/${deleteId}`);
        const result = await response.data;
        setMessage(result);
        setSnackbarOpen(true);
        setConfirmDeleteDialogState(false);
        if (result.status) {
            setTimeout(() => {
                navigate(`/vpn`, { replace: true });
            }, 1000);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Box>
            <SectionHeader heading="VPN Tunnel" />
            <Box sx={{ width: '100%', marginTop: 2 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={12} xl={12} >
                        {loading ? <Loading /> :
                            (_.has(profile, 'name')) ?
                                <VPNTunnelBasicInfoCard
                                    vpnProfile={profile}
                                    onDelete={handleConfirmDeleteDialogOpen}
                                    refresh={getData}
                                /> : null}
                    </Grid>
                    <Grid item xs={12} lg={6} xl={6}>
                        {loading ? <Loading /> :
                            (_.has(profile, 'devices')) ?
                                <VPNTunnelDeviceListCard
                                    tunnelId={profile._docId}
                                    vpnProfile={profile.devices}
                                    data={profile}
                                    refresh={getData}
                                /> : null}
                    </Grid>
                    <Grid item xs={12} lg={6} xl={6} >
                        {loading ? <Loading /> :
                            (_.has(profile, 'users')) ?
                                <VPNTunnelUserListCard
                                    data={profile}
                                    refresh={getData}
                                /> : null}
                    </Grid>
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        open={snackbarOpen}
                        onClose={handleSnakbarClose}
                        autoHideDuration={4000}>
                        {
                            snackbarOpen ?
                                <Alert severity={(message['status']) ? "success" : "error"} onClose={handleSnakbarClose}>
                                    {
                                        message['message']
                                    }
                                </Alert>
                                : null
                        }
                    </Snackbar>
                </Grid>

            </Box>
            <ConfirmDelete key={deleteId}
                open={confirmDeleteDialogState}
                handleClose={handleConfirmDeleteDialogClose}
                handleDelete={deleteData}
            />
        </Box>
    )
}