import dayjs from "dayjs";
import _ from 'lodash';
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import jwtDecode from "jwt-decode";
const ACCESS_TOKEN_KEY = "_aid";
// import convert from 'convert-units';

// export const setDataToLocalStorage = ()=>{
//     localStorage.setItem('accessToken', )
// }

// ///////////////////////////////////////////////////////////////////////////////////////////
// const decodeToken = (token) => {
//     if (!token) {
//         console.error("No token provided.");
//         return null;
//     }

//     try {
//         return jwtDecode(token);
//     } catch (error) {
//         console.error("Invalid token:", error.message);
//         return null;
//     }
// };

// const tokenq = localStorage.getItem("authToken"); // Or wherever you store the token
// const decoded = decodeToken(tokenq);
// console.log(decoded);

// const tokents = localStorage.getItem("authToken");
// console.log("Token from storage:", tokents);

// const tokentest = "your.jwt.token";
// console.log(tokentest.split(".").length === 3 ? "Valid format" : "Invalid format");

// const isTokenExpired = (token) => {
//     if (!token) return true;

//     try {
//         const { exp } = jwtDecode(token);
//         return exp * 1000 < Date.now();
//     } catch (error) {
//         return true;
//     }
// };

// const token = localStorage.getItem("authToken");
// console.log(isTokenExpired(token) ? "Token expired or invalid" : "Token is valid");

// ///////////////////////////////////////////////////////////////////////////////////////////

// export const getCurrentUser = () => {
//     const t = localStorage.getItem('_aid');
//     if (t) return jwtDecode(t);
//     return null;
// }

// export const getAid = () => {
//     const aid = localStorage.getItem('_aid');
//     if (aid) return aid;
//     return null;
// }
// export const getRid = () => {
//     const r = localStorage.getItem('_rid');
//     if (r) return r;
//     return null;
// }


// export const getPermissionObject = () => {
//     const aid = getAid();
//     const session = jwtDecode(aid);
//     const permissionString = session.permissions
//     const permissionObject = JSON.parse(atob(permissionString));
//     if (permissionObject) return permissionObject;
//     return null;
// }

export const getCurrentUser = () => {
    const t = localStorage.getItem('_aid');
    if (!t) return null;

    try {
        return jwtDecode(t);
    } catch (error) {
        console.error("Invalid JWT token:", error.message);
        return null;
    }
};

export const getAid = () => {
    const aid = localStorage.getItem('_aid');
    return aid ? aid : null;
};

export const getRid = () => {
    const r = localStorage.getItem('_rid');
    return r ? r : null;
};

export const getPermissionObject = () => {
    const aid = getAid();

    if (!aid) {
        console.error("No _aid token found.");
        return null;
    }

    let session;
    try {
        session = jwtDecode(aid);
    } catch (error) {
        console.error("Error decoding _aid token:", error.message);
        return null;
    }

    if (!session.permissions) {
        console.error("No permissions found in the token.");
        return null;
    }

    try {
        const permissionObject = JSON.parse(atob(session.permissions));
        return permissionObject;
    } catch (error) {
        console.error("Error decoding permissions:", error.message);
        return null;
    }
};

export const isTokenExpired = () => {
    let accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (accessToken) {

        const decodedToken = jwtDecode(accessToken);
        const token_issued_time = dayjs.unix(decodedToken.iat);
        const token_expiry_time = dayjs.unix(decodedToken.exp);
        const isAccessTokenValid = dayjs().isBetween(token_issued_time, token_expiry_time);
        console.log('isAccessTokenValid:', isAccessTokenValid);
        if (isAccessTokenValid) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}


export const setDefaultTimezone = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    localStorage.setItem("timezone", dayjs.tz.guess());
}

export const getDefaultTimezone = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    return dayjs.tz.guess();
}

export const getTimezone = () => {
    return localStorage.getItem("timezone");
}

export const setTimeZone = (t) => {
    localStorage.setItem("timezone", t);
}

export const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const getConvertedSpeed = (s) => {
    if (s.length === 0) {
        return '-';
    }
    if (parseInt(s) === 0) {
        return '0 B/s'
    }
    if (s.length <= 3) {
        return `${s} B/s`;
    }
    if (s.length > 3 && s.length <= 6) {
        const r = _.round((parseInt(s) / Math.pow(10, 3)), 2);
        return `${r} KB/s`;
    }
    if (s.length > 6 && s.length <= 9) {
        const r = _.round((parseInt(s) / Math.pow(10, 6)), 2);
        return `${r} MB/s`;
    }
    if (s.length > 9 && s.length <= 12) {
        const r = _.round((parseInt(s) / Math.pow(10, 9)), 2);
        return `${r} GB/s`;
    }
    if (s.length > 12) {
        const r = _.round((parseInt(s) / Math.pow(10, 12)), 2);
        return `${r} TB/s`;
    }

}
