import _ from 'lodash';
import { Box, Typography, Grid, CardContent } from "@mui/material";
import Loading from '../../components/Common/Loading.Component';
import StyledCard from "../../components/Card/StyledCard.component";

const GeneralInfoCard = ({ profile, loading }) => {
    const firmwareVersion = (_.has(profile, 'general.firmwareVersion')) ? profile.general.firmwareVersion : '-';
    const temperature = (_.has(profile, 'general.temperature') && !_.isEmpty(profile.general.temperature)) ? `${profile.general.temperature} °C` : '-';

    return (
        <StyledCard>
            <CardContent>
                <Typography variant="h5" color='primary.dark' gutterBottom>
                    General Information
                </Typography>

                <Grid container spacing={2} >
                    {/* <Grid item xs={6}>
                        <Box>
                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>Mac</Typography>
                            <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>
                                {macAddress}
                            </Typography>
                        </Box>
                    </Grid> */}
                    {/* <Grid item xs={6}>
                        <Box>
                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>Connection Type</Typography>
                            <Stack
                                direction="row"
                                divider={<Divider orientation="vertical" flexItem />}
                                spacing={2}
                            >
                                <Item>
                                    {
                                        connectionType == 1 ? <WifiIcon sx={{ color: 'green' }} /> : <WifiIcon />
                                    }
                                </Item>
                                <Item>
                                    {
                                        connectionType == 2 ? <SimCardIcon sx={{ color: 'green' }} /> : <SimCardIcon />
                                    }
                                </Item>
                                <Item>
                                    {
                                        connectionType == 3 ? <LanIcon sx={{ color: 'green' }} /> : <LanIcon />
                                    }
                                </Item>
                            </Stack>
                        </Box>
                    </Grid> */}
                    <Grid item xs={6}>
                        {loading ? <Loading /> :
                            <Box>
                                <Typography variant="subtitle1" sx={{ color: '#707070' }}>System</Typography>
                                {/* <Divider /> */}
                                <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>
                                    {firmwareVersion}
                                </Typography>
                            </Box>}
                    </Grid>
                    <Grid item xs={6}>
                        {loading ? <Loading /> :
                            <Box>
                                <Typography variant="subtitle1" sx={{ color: '#707070' }}>Temperature</Typography>
                                {/* <Divider /> */}

                                <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>
                                    {temperature}</Typography>
                            </Box>}
                    </Grid>
                </Grid>
            </CardContent >
        </StyledCard >
    );
};


export default GeneralInfoCard;