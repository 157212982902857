import { useState, useEffect } from "react";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getPermissionObject } from "../../helper/Utility.helper";

// MUI Components
import { Box, Container, Typography } from '@mui/material';

//Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import NavigationComponent2 from "../../components/Common/NavigateComponent2.component";
import DataTable from "../../components/DataTable/DataTable.component";
import { CreateRole, contextActions, actions } from './Actions'
import { SearchQuery } from "../../components/Search/SearchQuery";

const columns = [
    {
        id: 1,
        name: 'Roles',
        selector: row => row['name'],
        sortable: true,
        cell: row => <NavigationComponent2 id={row._docId} name={_.toUpper(row.name)} />,
    },
    {
        id: 2,
        name: 'Organization Name',
        sortable: true,
        cell: row =>
            <Box>
                <Box >
                    <Typography>{_.toUpper(row['organizationName'])}</Typography>
                </Box>
                <Box >
                    <Typography variant="caption">{`Level : ${row['level']}`}</Typography>
                </Box>
            </Box>
    },
];

const RoleList = () => {

    const navigate = useNavigate();
    const [queryInput, setQueryInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    //permission decoding
    const permissionObject = getPermissionObject();
    const rolesCreatePermission = permissionObject.roles.create;

    // const handleNavigate = () => navigate('createRole');
    const handleNavigate = () => navigate('create-role');

    const getStoredRowsPerPage = () => {
        return Number(sessionStorage.getItem("rolesTableRows")) || 5;
    };

    const [rowsPerPage, setRowsPerPage] = useState(getStoredRowsPerPage);

    // Store rows per page value in session storage when it changes
    const handlePerRowsChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        sessionStorage.setItem("rolesTableRows", newRowsPerPage);
    };

    const deleteAll = () => {
        const rows = selectedRows.map(r => r.name);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, rows, 'name'));
        }
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    const handleQueryInput = (event) => {
        const query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }
    const getData = async () => {
        setLoading(true);
        const response = await axios.get("api/roles");
        const result = await response.data;
        setData(result.data);
        setLoading(false);
    }

    useEffect(() => {
        getData();
        return () => {
            setData([]);
        }
    }, []);

    return (
        <Box>
            {/* Sectoin Header */}
            <SectionHeader heading="Roles" />
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <Container>
                    <DataTable
                        title={rolesCreatePermission ? <CreateRole handleNavigate={handleNavigate} /> : null}
                        columns={columns}
                        data={SearchQuery(queryInput, data, ['name', 'organizationName'])}
                        defaultSortField="name"
                        defaultSortFieldId={1}
                        actions={actions({ handleQueryInput, getData })}
                        contextActions={contextActions(deleteAll)}
                        onSelectedRowsChange={handleChange}
                        clearSelectedRows={toggleCleared}
                        progressPending={loading}
                        selectableRows={false}
                        selectableRowsHighlight={false}
                        paginationPerPage={rowsPerPage} // Use stored value
                        onChangeRowsPerPage={handlePerRowsChange}
                    />
                </Container>
            </Box>
        </Box>
    );
}

export default RoleList;