import axios from "axios";
import { useState, useEffect } from "react";
import DeviceCountColumnChart from "../../layouts/Charts/DeviceCountColumnChart.layout";
import Loading from "../../components/Common/Loading.Component"

export default function CountByModelBarChart() {
    const [isData, setIsData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({

        series: [{
            name: 'Device',
            data: [] //['AG-1621', 'AG-702', 'AG-207', 'ALWG-1638'],
        }],
        options: {
            chart: {
                type: 'bar',

                maxHeight: 420,
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    borderRadiusApplication: 'end', // 'around', 'end'
                    borderRadiusWhenStacked: 'last', // 'all', 'last'
                    dataLabels: {
                        total: {
                            enabled: false,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900,
                            }
                        }
                    }
                },
            },
            xaxis: {
                categories: [],
            },
            legend: {
                position: 'right',
                offsetY: 40
            },
            fill: {
                opacity: 1
            }
        },


    });
    const getData = async () => {
        setLoading(true);
        axios.get('api/overview/countbymodel')
            .then(response => {
                const data = response.data;
                if (data.status) {
                    setIsData(true);


                    const newOptions = {
                        ...state,
                        series: [{
                            name: "Models",
                            data: data.data.map(c => parseInt(c.count))
                        }]
                    }

                    newOptions['options']['xaxis']['categories'] = data.data.map(m => m.model.toUpperCase())
                    setState(newOptions);
                }
                setLoading(false);
            }).catch(error => {
                console.log('An error occurred while fetching chart data', error);
            }).finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getData();
    }, []);
    return (
        <div>
            {loading ? <Loading /> :
                isData && state.options.xaxis.categories.length > 0 && state.series[0].data.length > 0 ?
                    <DeviceCountColumnChart options={state.options} series={state.series} />
                    : 'Data Not Available'
            }

        </div>
    )
}