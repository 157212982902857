import { useState } from "react";
import { getAid } from "../../helper/Utility.helper";
import jwtDecode from "jwt-decode";
// Utility
import _ from "lodash";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

// Logo
import logo from '../../assets/IconWhite.png';

// Routing
import { useNavigate } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../services/login.service";

// Material UI Components
import { useTheme } from '@mui/material/styles';

import {
    Box, Typography, Divider, IconButton, Toolbar, Menu, MenuItem
} from "@mui/material";

// MUI Icons 
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import { FiSidebar } from "react-icons/fi";

import { Drawer, AppBar, DrawerHeader, } from '../../components/Nav/NavItemsStyle.component'
import AccountAvatar from "../../components/Nav/AccountAvatar.component";
import NavList from "../../components/Nav/Nav.component";

export default function Main() {
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);

    const dispatch = useDispatch();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(true);

    const aid = getAid();
    const session = jwtDecode(aid);

    const user = useSelector(state => state.authenticate.user);
    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfile = () => {
        handleClose();
        navigate('profile');
    }

    const handleLogout = () => {
        handleClose();
        dispatch(logoutUser());
    }
    return (
        <>
            {/* AppBar (welcome to atra, company name, account avtar ) */}
            <AppBar position="fixed" open={open} style={{ backgroundColor: '#134E5E' }} >
                <Toolbar sx={{ backgroundColor: 'rgba(0,0,0,0.0)' }}>
                    {open ?

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerClose}
                            sx={{
                                marginRight: 5,
                            }}
                        >
                            {theme.direction === 'rtl' ? <FiSidebar /> : <FiSidebar />}
                        </IconButton> : <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                // ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    }

                    <Typography variant="h6" noWrap component="div">
                        {'Welcome to Atra'}
                    </Typography>
                    <Typography variant="subtitle2" noWrap component="div" sx={{ ml: 0.5, mt: 0.3 }}>{'(Beta)'}</Typography>

                    <Box component="div" sx={{ flexGrow: 1 }}>
                        <Box sx={{ float: 'right', display: 'flex', alignItems: 'center' }} >

                            <Typography variant="h6" noWrap component="div">
                                {_.toUpper(session.organizationName)}
                            </Typography>

                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >

                                <AccountAvatar user={user} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
                                        <Box sx={{ fontSize: '20px', fontWeight: 'bold', color: "#808080" }}>
                                            {
                                                _.isEmpty(user) ? "ZOMBIE USER" : _.toUpper(user.name)
                                            }
                                        </Box>
                                        <Box sx={{ fontSize: '14px', color: "#A4A5A5" }}>
                                            {
                                                _.isEmpty(user) ? "zombie@zombieland.zom" : user.email
                                            }
                                        </Box>
                                        {/* <Box sx={{ fontSize: '12px', color: "#A4A5A5" }}>
                                            {
                                                _.isEmpty(user) ? "x hours ago" : dayjs(user.lastLoginTime).fromNow()
                                            }
                                        </Box> */}
                                    </Box>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleProfile}>
                                    <ListItemIcon>
                                        <AccountCircle />
                                    </ListItemIcon>
                                    Profile
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    <ListItemIcon>
                                        <LogoutRoundedIcon fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Sidebar */}
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <img src={logo} alt="Atra Logo" height={'48px'} />
                    {/* <Box sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',

                    }} */}


                    <Typography align="center" variant="h5" component="div" sx={{
                        color: '#f2f2f2', pt: 1, ml: 1
                    }}>
                        ATRA
                    </Typography>
                    {/* </Box> */}
                </DrawerHeader>
                <Divider />
                <NavList open={open} />
            </Drawer>
        </>
    )
}