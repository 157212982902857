import { Routes, Route } from "react-router-dom";
import { getPermissionObject } from "../../helper/Utility.helper";
import DeviceProfile from "../../sections/Devices/DeviceProfile.section";
import DeviceList from "../../sections/Devices/DevicesList.section";
import AddDevices from "../../sections/Devices/AddDevices.section";
export default function DevicesMain() {

    //permission decoding
    const permissionObject = getPermissionObject();
    const devicesAddPermission = permissionObject.devices.create;

    return (
        <Routes>
            <Route path="/" element={<DeviceList />} />
            <Route path="/:serial" element={<DeviceProfile />} />
            <Route path='/add-devices' element={devicesAddPermission ? <AddDevices /> : null} />
        </Routes>
    );
}

