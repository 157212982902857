import jwtDecode from "jwt-decode";
import axios from "axios";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { Navigate } from "react-router-dom";
// import { useDispatch } from 'react-redux';
// import { logoutUser } from "./login.service";
// import { store } from "../index"
const ACCESS_TOKEN_KEY = "_aid";
const REFRESH_TOKEN_KEY = "_rid";
// const REFRESH_ENDPOINT = "http://192.168.10.10:5001/auth/refreshtoken"; // Replace with actual endpoint
const REFRESH_ENDPOINT = "https://iwsmq9rwku.ap-south-1.awsapprunner.com/auth/refreshtoken"; // Replace with actual endpoint

export async function checkUserSession() {
    try {
        dayjs.extend(isBetween);
        let accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        if (accessToken) {
            const decodedToken = jwtDecode(accessToken);
            const token_issued_time = dayjs.unix(decodedToken.iat);
            const token_expiry_time = dayjs.unix(decodedToken.exp);
            const isAccessTokenValid = dayjs().isBetween(token_issued_time, token_expiry_time);
            // console.log('isAccessTokenValid:', isAccessTokenValid);
            if (isAccessTokenValid) {
                return decodedToken;
            } else {
                <Navigate to="/login" replace />
            }
        }

        return await refreshAccessToken();
    } catch (error) {
        return Promise.reject("Failed to retrieve a valid token: " + error.message);
    }
}

async function refreshAccessToken() {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (!refreshToken) {
        return Promise.reject("Missing refresh token");
    }

    try {
        const response = await axios.post(
            REFRESH_ENDPOINT,
            { refreshToken }
        );

        if (response.status !== 200) {
            // store.dispatch(logoutUser()); // ✅ Using store.dispatch() instead of useDispatch()
            <Navigate to="/login" replace />
            return Promise.reject("API returned an error: " + response.status);
        }

        if (!response.data.accessToken) {
            throw new Error("Invalid response structure");
        }

        localStorage.setItem(ACCESS_TOKEN_KEY, response.data.accessToken);
        return jwtDecode(response.data.accessToken);
    } catch (error) {
        // store.dispatch(logoutUser()); // ✅ Using store.dispatch() to trigger logout
        return Promise.reject("Error refreshing token: " + error.message);
    }
}

