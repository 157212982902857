import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// @mui
import { Box, Card, Chip, Grid, Typography, CardContent } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import _ from 'lodash';
import { StyledCover, StyledCardMedia } from './DeviceMediaCardStyle'
import Loading from '../../components/Common/Loading.Component';


const DeviceMediaCard = ({ profile, loading }) => {
    dayjs.extend(relativeTime);
    const title = profile.model;
    const cover = profile.modelImageURI;
    return (
        <Box>
            <Card sx={{
                position: 'relative',
                backgroundImage: 'linear-gradient(to bottom left, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)',
                color: 'white'
            }} elevation={3}>
                <StyledCardMedia>
                    {loading ? <Loading /> :
                        <StyledCover alt={title} src={cover} />}
                </StyledCardMedia>
                <CardContent sx={{ pt: 4, backgroundColor: 'rgba(255,255,255,0.2)' }}>
                    {loading ? <Loading /> :
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="subtitle1" >Serial</Typography>
                                    <Typography variant="caption" component={Box} >{profile.serial}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="subtitle1" >Connection Status</Typography>
                                    {
                                        profile.connectionStatus ? (
                                            <Box sx={{ display: "flex", alignItems: "center", my: '0.5em' }}>
                                                <Chip label="Online" color="success" size="small" icon={<CheckCircleIcon />} />
                                            </Box>
                                        ) : (
                                            <Box sx={{ display: "flex", alignItems: "center", my: '0.5em' }}>
                                                <Chip label="Offline" color="error" size="small" icon={<ErrorIcon />} />
                                            </Box>
                                        )
                                    }
                                    {_.has(profile, "connectionStatusTime") ?
                                        <Typography variant="caption" sx={{ color: 'inherit', display: 'block' }}>
                                            {`last updated ${dayjs(profile.connectionStatusTime).fromNow()}`}
                                        </Typography> : null}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="subtitle1" >Model</Typography>
                                    <Typography
                                        color="inherit"
                                        variant="caption"
                                    >
                                        {_.toUpper(title)}
                                    </Typography>
                                </Box>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Box>
                                    <Typography variant="subtitle1" >Description</Typography>
                                    <Typography
                                        color="inherit"
                                        variant="caption"
                                    >
                                        An Industrial IoT gateway is a hardware device or software program that acts as a bridge between industrial equipment and the cloud.
                                    </Typography>
                                </Box>
                            </Grid> */}
                        </Grid>}
                </CardContent>
            </Card>
        </Box>
    );
}
export default DeviceMediaCard;