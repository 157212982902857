import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";

// @Mui
import {
    Box, Accordion, AccordionDetails, Switch, TextField, MenuItem, FormControlLabel, Button,
    Dialog, DialogTitle, FormLabel, FormHelperText, FormControl, Snackbar, TableContainer, Table, TableRow,
    Grid, Stack, IconButton, Tooltip, Divider, Typography, TableCell, TableBody, DialogContent, Alert,
} from '@mui/material';
import { styled } from "@mui/material/styles";
import MuiAccordionSummary from '@mui/material/AccordionSummary';

// @Mui-icons
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// Custom Components
import Loading from "../../components/Common/Loading.Component";

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

export default function AddDeviceForm({ openDialogbox, handleClose, tunnelId, refresh }) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [deviceList, setDeviceList] = useState([]);
    const [device, setDevice] = useState([]);
    const [networkForwarding, setNetworkForwarding] = useState(false);
    const [ip, setIP] = useState([]);
    const [deviceGroup1, setDeviceGroup1] = useState([]);
    const [deviceGroup, setDeviceGroup] = useState([]);

    const handleSelectDeviceChange = (e) => {
        setError({ selectedDevice: false })
        setDevice(e.target.value);
    };

    const handleNetworkForwardingChange = (e) => {
        setNetworkForwarding(e.target.checked);
        if (e.target.checked === false) {
            setIP([]);
        }
    };

    const handleAddIP = () => {
        const maxIPCount = 10
        const ipList = [...ip, ""];
        if (ipList.length <= maxIPCount) {
            setIP(ipList);
        }

    };

    const handleRemoveIP = (i) => {
        const ipList = [...ip];
        ipList.splice(i, 1);
        setIP(ipList)
    };

    const handleIPChange = (e, i) => {
        setError({ ip: false })
        const inputIP = [...ip]
        inputIP[i] = e.target.value.trim();
        setIP(inputIP)
    }

    const selectedDeviceValidation = () => {
        const result = (device.length === 0) ? false : true;
        result ? setError({ selectedDevice: false }) : setError({ selectedDevice: true })
        return result
    }

    const ipValidation = () => {
        if (networkForwarding) {
            if (ip.length >= 1) {
                const uniqueIPList = new Set(ip); //Set: only store unique values and every takes all elements one by one from ip array and returns true or false
                return ip.every(ipAddr => {
                    const regex = /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[1-9])\.){3}0$/;
                    const result = regex.test(ipAddr);
                    result ? setError({ ip: false }) : setError({ ip: true });
                    return result
                }) && ((uniqueIPList.size === ip.length) && (uniqueIPList.size <= 10) ? (true) || (setError({ deviceIP: false })) : (false) || (setError({ deviceIP: true })));
            }
        } else {
            return true;
        }
    };

    const checkDuplicateIPFromDeviceGroup = () => {
        if (deviceGroup1.length >= 1) {
            for (const d of deviceGroup1) {
                const dIPs = d.ip;
                for (const dIP of dIPs) {
                    if (ip.includes(dIP)) {
                        const result = false;
                        result ? setError({ duplicateIP: false }) : setError({ duplicateIP: true })
                        return result;
                    }
                }
            }
            return true;
        } else {
            return true
        }
    }

    const handleAddDeviceToGroup = () => {
        const dd = _.unionBy(device.device, [{ _docId: device._docId, name: device.name, serial: device.serial, ip, networkForwarding }])[0]
        const newDevice = { device, _docId: device._docId, name: device.name, serial: device.serial, ip, networkForwarding };
        const nDevice = dd

        if (selectedDeviceValidation() && ipValidation() && checkDuplicateIPFromDeviceGroup()) {
            setDeviceGroup1([...deviceGroup1, newDevice]);
            setDeviceGroup([...deviceGroup, nDevice]);
            setDevice([]);
            setNetworkForwarding(false);
            setIP([]);

            // removes selected device(inputDevice) from list
            const inputDevice = deviceList.indexOf(deviceList.find(obj => device === obj));
            deviceList.splice(inputDevice, 1);
            setDeviceList(deviceList);
        }
    }

    const handleRemoveDevice = (i) => {
        const allDevice = [...deviceGroup1];
        const removedDevice = allDevice.splice(i, 1); //It gives array of single device with IP and Network Forwarding status.
        const removedDeviceObject = removedDevice[0].device; //It gives object of single device.
        setDeviceGroup1(allDevice)
        const updatedDevicelist = [...deviceList];
        updatedDevicelist.push(removedDeviceObject);
        setDeviceList(updatedDevicelist);

        const allDevice2 = [...deviceGroup];
        const removeDevice2 = allDevice2.splice(i, 1);
        const removedDeviceObject2 = removeDevice2[0];
        setDeviceGroup(allDevice2);
        const updatedDeviceList2 = [...deviceList];
        updatedDeviceList2.push(removedDeviceObject2);
        setDeviceList(updatedDeviceList2);
    };

    const handleCloseSnackbar = () => {
        setOpen(false);
    }

    const handleReset = () => {
        setDevice([]);
        setNetworkForwarding(false);
        setIP([]);
        setDeviceGroup1([]);
        setDeviceGroup([])
        getDeviceData();
        setError({});
    }

    const deviceGroupValidation = () => {
        const result = deviceGroup1.length === 0 ? false : true
        result ? setError({ deviceGroup: false }) : setError({ deviceGroup: true });
        return result;
    }

    const handleAddDeviceToVPNTunnel = async () => {
        if (deviceGroupValidation()) {
            const formObject = { deviceGroup, tunnelId };
            await postData(formObject);
            handleReset();
        }
    }

    const getDeviceData = async () => {
        setLoading(true);
        const response = await axios.get(`api/vpn/getvpndevicelisttype2?vpnTunnelId=${tunnelId}`);
        const data = await response.data;
        setDeviceList(data.data);
        setLoading(false);
    }

    const postData = async (data) => {
        axios.post('api/vpn/add_devices', data).then(res => {
            const result = res.data;
            handleClose();
            setMessage(result);
            setOpen(true);
        }).catch(err => {
            handleClose();
            setMessage(err.response.data);
            setOpen(true);
        });
    }

    useEffect(() => {
        getDeviceData();
        return () => handleReset()
    }, []);

    useEffect(() => {
        if (!_.isEmpty(message) && !open) refresh();
    }, [message, open]);

    return (
        <Box >
            <Dialog
                open={openDialogbox}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle>Add Device</DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[800],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Divider />

                <DialogContent sx={{ height: 440 }}>
                    <Grid container sx={{ height: 376 }}>
                        <Grid item xs={5.7} sx={{ mt: 2.5, ml: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <FormControl sx={{ width: 300 }}>
                                <TextField
                                    select
                                    size="small"
                                    label="Select Device"
                                    name="device"
                                    value={device}
                                    onChange={handleSelectDeviceChange}
                                    error={error.selectedDevice ? true : false}
                                    helperText={error.selectedDevice ? "Select Device" : null}
                                    required
                                >
                                    {
                                        (loading) ? (<Loading />) : deviceList.length !== 0 ?
                                            deviceList.map((obj, index) => <MenuItem key={obj._docId} value={obj}>{_.toUpper(obj.name)}</MenuItem>) :
                                            <Typography sx={{ ml: 3 }} color="primary">Data Not Available</Typography>
                                    }
                                </TextField>
                            </FormControl>

                            {
                                device.length !== 0 ?
                                    <FormControl component="fieldset" variant="standard">
                                        <FormControlLabel
                                            control={
                                                <Switch size="small" checked={networkForwarding} onChange={handleNetworkForwardingChange} />
                                            }
                                            label="Network Forwarding"
                                        />
                                    </FormControl> : null
                            }

                            {
                                networkForwarding === true
                                    ?
                                    // <Box>ok</Box>
                                    <Item sx={{ mt: 2, maxHeight: 260, overflow: 'auto' }}>
                                        <Stack
                                            direction="column"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Item>
                                                <Stack
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    spacing={1}
                                                >
                                                    {
                                                        ip.map((s, i) => (
                                                            <Item key={i}>
                                                                <Grid
                                                                    container
                                                                    justifyContent="center"
                                                                    alignItems="stretch"
                                                                    spacing={2}

                                                                >
                                                                    <Grid item xs={1} md={1}>
                                                                        <IconButton
                                                                            sx={{ mt: 3 }}
                                                                            onClick={() => handleRemoveIP(i)}
                                                                            variant="outlined"
                                                                            aria-label="Remove"
                                                                            color="error"
                                                                        >
                                                                            <RemoveCircleRoundedIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item xs={11} md={11}>
                                                                        <FormControl fullWidth>
                                                                            <FormLabel component="legend">IP {i + 1}</FormLabel>
                                                                            <TextField
                                                                                sx={{ ml: 3 }}
                                                                                size="small"
                                                                                name={`IP[${i}]`}
                                                                                type="text"
                                                                                value={s}
                                                                                // onChange={(e) => handleSerialChange(index, e.target.value)}
                                                                                onChange={e => handleIPChange(e, i)}
                                                                                placeholder="Enter IP"
                                                                                error={error.ip ? true : false}
                                                                                helperText={error.ip ? "Enter Valid IP Address" : null}
                                                                                required
                                                                            />

                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            </Item>
                                                        ))
                                                    }
                                                </Stack>
                                            </Item>
                                            {error.deviceIP ? < FormHelperText error >You have entered same IP in two or more than two fields</FormHelperText> : null}
                                            <Item>
                                                <IconButton variant="contained"
                                                    onClick={handleAddIP}
                                                    aria-label="Add IP"
                                                    color="warning"
                                                    disabled={ip.length === 10 ? true : false}
                                                >
                                                    <AddCircleRoundedIcon />
                                                </IconButton>
                                                {ip.length === 10 ? <Typography>You have reached the maximum limit of IP addresses</Typography> : null}
                                            </Item>
                                        </Stack>
                                    </Item>
                                    : null
                            }
                            {networkForwarding && ip.length === 0 ? < FormHelperText  >Add IPs</FormHelperText> : null}
                            {error.duplicateIP ? < FormHelperText error >IP Already Exists</FormHelperText> : null}
                        </Grid>

                        <Grid item xs={0.2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Divider orientation="vertical">
                                <Tooltip title="Add To Device Group">
                                    <IconButton aria-label="delete" size="large" onClick={handleAddDeviceToGroup}>
                                        <DoubleArrowIcon
                                            fontSize="inherit"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Divider>
                        </Grid>

                        <Grid item xs={5.5} sx={{ mt: 2.5, ml: 3, maxHeight: 350, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>

                            <Typography variant="h6">Device Group</Typography>

                            <Grid sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'start', justifyContent: 'start', overflow: 'auto' }}>
                                {deviceGroup1.map((x, i) =>
                                    <Grid item xs={10} sx={{ margin: 2 }} key={x.device.name}>
                                        <Accordion elevation={4} expanded={x.networkForwarding ? undefined : false}>
                                            <AccordionSummary
                                                aria-controls="panel1-content"
                                                id="panel1-header"

                                            >
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                    <Box sx={{ ml: 1.5, display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                                        <Typography variant="subtitle1" >{x.device.name}</Typography>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                            <Typography variant="caption">Network Forwarding</Typography>
                                                            <Typography variant="caption">{x.networkForwarding ? "Enabled" : "Disabled"}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <IconButton aria-label="delete" color="error" onClick={() => handleRemoveDevice(i)}
                                                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <RemoveCircleOutlineIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>

                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <TableContainer sx={{ borderRadius: '15px' }}>
                                                    <Table size="small" aria-label="simple table">
                                                        <TableBody>
                                                            {x.ip.map((i, x) =>
                                                                <TableRow
                                                                    key={x}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">{i}</TableCell>

                                                                    <TableCell align="right">Node {x + 1}</TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                )}
                            </Grid>
                            {error.deviceGroup ? < FormHelperText error > Please Add Atleast One Device</FormHelperText> : null}
                        </Grid>
                    </Grid>

                    <Divider sx={{ mt: 2 }}></Divider>

                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="outlined" onClick={handleReset}>
                            Reset
                        </Button>
                        <Button onClick={handleAddDeviceToVPNTunnel} variant="contained">
                            Add Device Group
                        </Button>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Box>
                </DialogContent>

            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleCloseSnackbar}
                autoHideDuration={2250}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleCloseSnackbar}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    )
}