import axios from 'axios';
import {
    loginRequest, loginSuccess, loginFailure, logoutRequest
} from '../redux/actions/login.action';
// import { login, logout } from './auth.service';

export const loginUser = (payload) => async (dispatch) => {
    dispatch(loginRequest());

    axios.post("/auth/login", payload).then(response => {
        if (response.status === 200) {
            const data = response.data;
            localStorage.setItem('_aid', data.accessToken);
            localStorage.setItem('_rid', data.refreshToken);
            localStorage.setItem('_info', btoa(JSON.stringify(data.user)));
            dispatch(loginSuccess(data));
        }

    }).catch(error => {
        dispatch(loginFailure(error.response.data));
    })
    // await login(data['email'], data['password'],
    //     (response) => {
    //         // console.log("Login Success response", response);
    //         dispatch(loginSuccess(response))
    //     },
    //     (error) => {
    //         // console.log("Login Error", error);
    //         dispatch(loginFailure(error));
    //     });
}

export const getRefreshToken = async (rid) => {
    const response = await axios.post('/auth/refreshtoken', {
        'refreshToken': rid
    });
    return await response;
}

export const logoutUser = () => async (dispatch) => {
    // logout();
    await axios.post('/auth/logout', {
        "refreshToken": localStorage.getItem('_rid')
    })
    dispatch(logoutRequest());
}

