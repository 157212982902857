import { Grid, Box } from "@mui/material";
import ReactLoading from 'react-loading';
const Loading = () => (
    <Box sx={{ width: '100%' }}>
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <ReactLoading type='bars' color='#FBBD08' />
            </Grid>
        </Grid>
    </Box>

)
export default Loading;
