// import react from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { createTheme } from "@mui/material";
import _ from "lodash";
// import dayjs from "dayjs";
// import isBetween from "dayjs/plugin/isBetween";
// import jwtDecode from "jwt-decode";

// import { useSelector } from "react-redux";

// Components
import MainDashboard from "./pages/Dashboard/Main.page";
import LoginForm from "./pages/Authentication/Login.page";
import Invite from "./pages/Authentication/Invite.page";
// import Verification from "./pages/Authentication/Verification.page";
import PasswordRecovery from "./pages/Authentication/PasswordRecovery.page";
import RegisteredEmail from "./pages/Authentication/RegisteredEmail.page";
import indianOcean from "./assets/main.theme";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
// import { isTokenExpired } from "./helper/Utility.helper";
// import { store } from "./index"
// import { updateSession } from "./redux/actions/login.action";
// import { checkUserSession } from "./services/session.service";
// import { setTimeZone } from "./helper/Utility.helper";
const theme = createTheme(indianOcean);
function App() {

  const isAuthenticated = useSelector(state => state.authenticate.isAuthenticated);
  const isVerified = useSelector(state => state.authenticate.isVerified);

  const currentLocation = useLocation();
  const from = _.get(currentLocation, "state.from.pathname", "/");

  // let isToken = isTokenExpired();
  // if (!isToken) {
  //   checkUserSession().then(session => {
  //     console.log("current user:", session);

  //     const newState = {
  //       isAuthenticated: true,
  //       isVerified: session.isVerified,
  //       _aid: localStorage.getItem("_aid"),
  //       user: JSON.parse(atob(localStorage.getItem("_info"))),
  //     };

  //     store.dispatch(updateSession(newState)); // ✅ Dispatch Redux action
  //     setTimeZone(session.zoneinfo);
  //   })
  //     .catch(err => {
  //       console.log("An error occurred while validating user session:", err);
  //     });
  // }

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Routes>
          <Route
            path="/*"
            element={isAuthenticated && isVerified ? <MainDashboard /> : <Navigate to="/login" replace />}
          />
          <Route
            path="/login"
            element={!isAuthenticated ? <LoginForm /> : <Navigate to={from} replace />}
          />
          <Route
            path="/invite"
            element={isAuthenticated ? <Navigate to={from} replace /> : <Invite />}
          />
          <Route
            path="/password-recovery"
            element={isAuthenticated ? <Navigate to={from} replace /> : <PasswordRecovery />}
          />
          <Route
            path="/forgot-password"
            element={isAuthenticated ? <Navigate to={from} replace /> : <RegisteredEmail />}
          />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;