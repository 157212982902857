import _ from 'lodash';
import dayjs from 'dayjs';
// Mui
import { Box, Paper, Typography, Grid, CardContent } from "@mui/material";
// import PendingActionsIcon from '@mui/icons-material/PendingActions';

// Custom Components
import StyledCard from "../../components/Card/StyledCard.component";
import Loading from '../../components/Common/Loading.Component';
// utility
import { getConvertedSpeed } from '../../helper/Utility.helper';

const EthernetStatusCard = ({ profile, loading }) => {
    const lastUpdated = (_.has(profile, 'utilization')) ? profile.utilization.timeStamp : '0';
    const ethernet = (_.has(profile, 'network.lan')) ? profile.network.lan : [];

    return (
        <StyledCard>
            <CardContent>
                <Typography variant="h5" color='primary.dark' component={Box} gutterBottom>
                    Ethernet Status
                </Typography>
                <Box display="flex" flexDirection="column">
                    {loading ? <Loading /> :
                        (!_.isEmpty(ethernet)) ? ethernet.map((obj, index) => (

                            <Grid
                                component={Paper}
                                container
                                key={obj.port}
                                spacing={1}
                                sx={
                                    (index === 0 && ethernet.length > 1) ? {
                                        p: 2, py: 4, mb: 3, position: 'relative'
                                    } : {
                                        p: 2, py: 4, position: 'relative'
                                    }
                                }
                            >
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>{_.upperCase(obj.slot)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    {/* <BsEthernet /> */}
                                    <Box sx={{
                                        backgroundColor: '#28B25F',
                                        // height: '1.5em',
                                        border: '1px solid #E1E1E1',
                                        borderRadius: '2.5px',
                                        textAlign: 'right',
                                        pr: 2,
                                        color: '#FFF',
                                        fontSize: 14
                                    }}
                                    >
                                        {getConvertedSpeed(obj.downloadSpeed)}
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>Interface Name</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{obj.interfaceName}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>Mac</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{obj.mac.replaceAll(':', '-').toUpperCase()}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>IPv4</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{obj.ipv4}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>Download Speed</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{getConvertedSpeed(obj.downloadSpeed)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>Upload Speed</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{getConvertedSpeed(obj.uploadSpeed)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>Downloaded Data</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{obj.downloadBytes}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>Upload Data</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{obj.uploadBytes}</Typography>
                                    </Box>
                                </Grid>
                                {_.has(profile, 'utilization') ?
                                    <Box sx={{ color: 'white', display: 'flex', flexDirection: 'column', px: 1, borderRadius: 1, position: 'absolute', right: 8, bottom: 6, backgroundColor: '#B2B2B2' }}>
                                        <Typography variant="caption">{`last updated on ${dayjs(lastUpdated).format('DD MMMM, YYYY h:mm:ss A')}`}</Typography>
                                    </Box>
                                    : null}
                            </Grid>
                        )) : 'no data found'
                    }
                </Box>

                {/* <Grid item xs={3}>
                    <Box>
                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>LAN 2</Typography>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ backgroundColor: '#28B25F', height: '1.5em', border: '1px solid #E1E1E1', borderRadius: '2.5px', textAlign: 'right', pr: 2, color: '#FFF' }} >
                        {`2.4 MB/s`}
                        </Box>
                        <Box  sx={{ height: '1.5em', border: '1px solid #E1E1E1', borderRadius: '2.5px' }} /> 
                </Grid> */}
            </CardContent>
        </StyledCard>
    );
}
export default EthernetStatusCard;