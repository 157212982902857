import { useState, useEffect } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import _ from "lodash"
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { getPermissionObject } from "../../helper/Utility.helper";
import { useSelector } from "react-redux";
import { StyledText } from "../../layouts/DeviceDetail/StyleBox";

//Mui
import { Box, IconButton, TableContainer, Table, TableBody, TableRow, TableCell, Tooltip, Card, CardHeader, CardContent } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import HideSourceIcon from '@mui/icons-material/HideSource';
import SyncIcon from '@mui/icons-material/Sync';

//Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import EditRole from "./EditRole.section";
import Loading from "../../components/Common/Loading.Component";

dayjs.extend(relativeTime);

export default function RoleProfile() {

    dayjs.extend(localizedFormat);
    const { _docId } = useParams();
    const [openEditUserNameDialog, setOpenEditUserNameDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState({});

    //permission decoding
    const permissionObject = getPermissionObject();
    const rolesUpdatePermission = permissionObject.roles.update;

    const user = useSelector(state => state.authenticate.user);

    //Handle for User Name Change
    const handleOpenEditUserNameChange = () => {
        setOpenEditUserNameDialog(true);
    }
    const handleCloseEditUserNameChange = () => setOpenEditUserNameDialog(false);
    const getData = async () => {
        setLoading(true);
        const response = await axios.get(`/api/roles/${_docId}`);
        const result = await response.data;
        setProfile(result.data);
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Box>
                <SectionHeader heading="Role" />
                <Card sx={{
                    mt: 4,
                    position: 'relative',
                    backgroundImage: 'linear-gradient(to bottom left, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)',
                    color: 'white',
                    borderRadius: '15px'
                }} elevation={3}>
                    {loading ? <Loading /> :
                        <CardContent sx={{ pt: 4, backgroundColor: 'rgba(255,255,255,0.2)', display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                            <Box sx={{ display: 'flex' }}>
                                <StyledText variant="h4" sx={{ color: '#fff', marginTop: 2 }}>
                                    {_.has(profile, "name") ? (_.toUpper(profile.name)) : '-'}
                                </StyledText>
                                {rolesUpdatePermission ?
                                    <IconButton sx={{ color: 'white' }}
                                        onClick={handleOpenEditUserNameChange}
                                    >
                                        <EditIcon />
                                    </IconButton> : null}
                            </Box>
                            <Box sx={{ pr: 16 }}>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <StyledText>Created by :</StyledText>
                                    <StyledText>{_.has(profile, "meta") ? _.toUpper(profile.meta.createdBy) : '-'}</StyledText>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <StyledText>Created At :</StyledText>
                                    <StyledText>{_.has(profile, "meta") ? (dayjs(profile.meta.createdAt).format('lll')) : '-'}</StyledText>
                                </Box>
                            </Box>
                            <Box sx={{ position: 'absolute', right: 22 }}>
                                <Tooltip title="Refresh">
                                    <IconButton aria-label="refresh" onClick={getData} sx={{ color: 'white' }}>
                                        <SyncIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </CardContent>
                    }
                </Card>

                <Box sx={{ mt: 4, display: 'flex', gap: 4, flexWrap: 'wrap' }}>

                    {(profile && profile.permissions) ?
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                            {Object.entries(profile.permissions)
                                .filter(([category]) => (user.level === 3 ? (category !== "organizations") && (category !== "remote") : (category !== "remote"))) // Remove remote category
                                .map(([category, actions]) => (
                                    <Card key={category} variant="outlined" sx={{ width: 300, borderRadius: "15px" }}>
                                        <CardHeader title={category.charAt(0).toUpperCase() + category.slice(1)} />
                                        <CardContent>
                                            <TableContainer sx={{ maxWidth: 300, borderRadius: "15px" }}>
                                                <Table size="small" sx={{ maxWidth: 300 }} aria-label="permissions-table">
                                                    <TableBody>
                                                        {Object.entries(actions).map(([action, allowed]) => (
                                                            <TableRow key={action} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                                <TableCell component="th" scope="row" sx={{ color: allowed ? "inherit" : "#808080" }}>
                                                                    {action.charAt(0).toUpperCase() + action.slice(1)}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Tooltip title={allowed ? "Allowed" : "Not Allowed"} placement="left">
                                                                        {allowed ? <CheckCircleIcon color="success" /> : <HideSourceIcon color="disabled" />}
                                                                    </Tooltip>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                ))}
                        </div>
                        : null}

                </Box>
            </Box>

            <EditRole
                key={profile._docId}
                openDialog={openEditUserNameDialog}
                handleClose={handleCloseEditUserNameChange}
                rName={_.has(profile, 'name') ? profile.name : '-'}
                id={_.has(profile, '_docId') ? profile._docId : '-'}
                permissionById={_.has(profile, 'permissions') ? profile.permissions : '-'}
                refresh={getData}
            />
        </>
    )
}