import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import _ from 'lodash'
import { getAid } from "../../helper/Utility.helper";
import jwtDecode from "jwt-decode";
import { getPermissionObject } from "../../helper/Utility.helper";
import { useSelector } from 'react-redux';

// Mui
import { Box, Grid, Snackbar, Alert, Button, MenuItem, Divider, FormControl, TextField, Typography } from "@mui/material";

// Custom Components
import SectionHeader from '../../components/SectionHeader/SectionHeader.component';
import Loading from '../../components/Common/Loading.Component';
import CheckingBoxes from "../../assets/CheckingBoxes.svg";
import PermissionCard from '../../layouts/Roles/PermissionCard.layout';

function setAllValuesToFalse(permissions) {
    const newPermissions = {};
    for (const category in permissions) {
        newPermissions[category] = {};
        for (const permission in permissions[category]) {
            newPermissions[category][permission] = false;
        }
    }
    return newPermissions;
}

const permissionObject = getPermissionObject();
let falseValued = setAllValuesToFalse(permissionObject)
const { remote, ...updatedFalseValued } = falseValued;

function CreateRole() {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [organizationList, setOrganizationList] = useState([]);
    const [organizationId, setOrganizationId] = useState("");
    const [permissions, setPermission] = useState(updatedFalseValued);
    const user = useSelector(state => state.authenticate.user);
    const aid = getAid();
    const session = jwtDecode(aid);

    const handleEvent = (e, groupKey) => {
        const { name, checked } = e.target;
        setPermission((prev) => {
            let updatedGroup = { ...prev[groupKey], [name]: checked };
            if (name !== "view" && checked) updatedGroup.view = true;
            if (name === "view" && !checked) Object.keys(updatedGroup).forEach((key) => (updatedGroup[key] = false));

            let updatedPermission = { ...prev, [groupKey]: updatedGroup };
            if ((groupKey === "remote" || groupKey === "vpn") && checked) updatedPermission.devices.view = true;
            if (groupKey === "devices" && name === "view" && !checked) {
                ["remote", "vpn"].forEach((key) => Object.keys(updatedPermission[key] || {}).forEach((k) => (updatedPermission[key][k] = false)));
            }
            return updatedPermission;
        });
    };

    const handleRoleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleOrganizationNameChange = (e) => {
        setOrganizationId(e.target.value);
    }

    const handleReset = () => {
        setName("");
        setOrganizationId("");
        setPermission(updatedFalseValued);
    }

    const handleCancel = () => {
        navigate("/roles");
    };

    const handleCloseSnackbar = () => {
        setOpen(false);
    }

    const handleSubmit = () => {
        const formData = { permissions, organizationId, name }
        // console.log({ permission, organizationId, name });
        postData(formData)
    };

    const getOrganizationList = async () => {
        setLoading(true);
        const response = await axios.get('api/organization');
        const result = await response.data;
        setOrganizationList(result.data);
        if (!_.isEmpty(result)) setLoading(false);
    }

    const postData = async (data) => {
        const response = await axios.post('api/roles', data);
        const result = await response.data;
        // console.log('Install response', response.status);
        setOpen(true);
        setMessage(result);
        if (result.status) {
            setTimeout(() => {
                navigate('/roles', { replace: true });
            }, 4000);
        }
    }

    useEffect(() => {
        if (user.level !== 3) {
            getOrganizationList();
        }
    }, []);

    return (
        <Box>
            <SectionHeader heading="Create New Role" />

            <Grid container spacing={1}>
                <Grid item xs={9} container spacing={1} sx={{ mt: 4, width: '100%', height: 'calc(100vh - 200px)', overflowY: 'scroll' }}>
                    <Box sx={{ mb: 3, width: '100%' }}>
                        <Typography variant="h6" sx={{ ml: 1 }}>Select Permissions</Typography>
                    </Box>
                    {Object.keys(permissions).filter((key) => (user.level === 3 ? key !== "organizations" : key)).map((key) => (
                        <Grid key={key} item xs={12} sm={12} md={6} lg={4}>
                            <PermissionCard
                                key={key}
                                title={key.charAt(0).toUpperCase() + key.slice(1)}
                                permissions={permissions[key]}
                                groupKey={key}
                                handleEvent={handleEvent}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Divider orientation="vertical" flexItem sx={{ m: 1 }}></Divider>

                <Grid item xs={2.8} sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box>
                        <img src={CheckingBoxes} width="150px" height="auto" alt="Role_icon" />
                    </Box>
                    <FormControl margin="normal" fullWidth sx={{ mt: 4 }}>
                        <TextField
                            size="small"
                            label="Role Name"
                            name="name"
                            value={name}
                            onChange={handleRoleNameChange}
                            required
                        />
                    </FormControl>
                    <FormControl margin="normal" fullWidth >
                        <TextField
                            select
                            size="small"
                            label="Organization Name"
                            name="organizationName"
                            value={organizationId}
                            onChange={handleOrganizationNameChange}
                            required
                        >
                            <MenuItem value={"current"}>{_.toUpper(session.organizationName)}</MenuItem>
                            <Divider />
                            {loading ? (<Loading />) : organizationList.length !== 0 ? (organizationList.map((obj, index) =>
                                (<MenuItem key={obj._docId} value={obj._docId}>{_.toUpper(obj.name)}</MenuItem>))) :
                                (<Typography sx={{ ml: 3 }} color="primary">Child organizations are not available</Typography>)
                            }
                        </TextField>
                    </FormControl>
                    <Box sx={{ width: '100%', mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="outlined" onClick={handleReset}>
                            Reset
                        </Button>
                        <Button variant="outlined" color="error" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Box>
                    <Button sx={{ width: '100%', mt: 4 }} onClick={handleSubmit} variant="contained">
                        Create Role
                    </Button>
                </Grid>

            </Grid>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleCloseSnackbar}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleCloseSnackbar}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default CreateRole;