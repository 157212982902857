// @mui
import { Box } from '@mui/material';

// Custom components
import SectionHeader from '../../components/SectionHeader/SectionHeader.component';
import OverviewSection from '../../sections/overview/OverviewSection';

const OverviewPage = () => {
    return (
        <Box>
            <SectionHeader heading='Overview' />
            <OverviewSection />
        </Box >
    );
}
export default OverviewPage;