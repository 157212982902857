import { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { getPermissionObject } from "../../helper/Utility.helper";

// Mui
import {
    Grid, Card, CardHeader, CardContent, IconButton, Tooltip, Alert, Snackbar,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from "@mui/material";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// Custom Component
import { BoxItem } from "../../components/Common/Item.component";
import AddUserForm from "../../sections/VPN/AddUserForm.section";
import ConfirmDelete from "../Delete.layout";

export default function VPNTunnelUserListCard({ data, refresh }) {

    const [createDialogState, setCreateDialogState] = useState(false);
    //permission decoding
    const permissionObject = getPermissionObject();
    const vpnCreatePermission = permissionObject.vpn.create;
    const vpnDeletePermission = permissionObject.vpn.delete;
    const [confirmDeleteDialogState, setConfirmDeleteDialogState] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState(null);

    const handleConfirmDeleteDialogOpen = (id) => {
        setConfirmDeleteDialogState(true);
        setDeleteId(id);
    }
    const handleConfirmDeleteDialogClose = () => setConfirmDeleteDialogState(false);
    const handleSnakbarClose = () => setSnackbarOpen(false);

    const deleteData = async () => {
        const response = await axios.delete(`/api/vpn/removeuser`, { data: { userId: deleteId, tunnelId: data._docId } });
        const result = await response.data;
        setMessage(result);
        setSnackbarOpen(true);
        setConfirmDeleteDialogState(false);
    }

    const handleCreateDialogOpen = () => setCreateDialogState(true);
    const handleCreateDialogClose = () => {
        setCreateDialogState(false)
    };
    useEffect(() => {
        if (!_.isEmpty(message) && !snackbarOpen) refresh();
    }, [message, snackbarOpen]);
    return (
        <>
            <Grid item >
                <Card elevation={4} sx={{ height: '100%', display: 'flex', flexDirection: 'column', borderRadius: '15px', backgroundColor: '#FAFAFA' }}>
                    <CardHeader
                        title="User List"
                        action={vpnCreatePermission && !data.isActive && data.isEnabled ?
                            <Tooltip title="Add User" placement="left">
                                <IconButton variant="contained"
                                    onClick={handleCreateDialogOpen}
                                    aria-label="Add Device"
                                    color="warning"
                                >
                                    <AddCircleRoundedIcon />
                                </IconButton>
                            </Tooltip>
                            : null}
                    />
                    <CardContent sx={{ pt: 0, flexGrow: 1 }}>
                        <BoxItem sx={{ textAlign: 'left', pt: 0, backgroundColor: 'inherit' }}>
                            <TableContainer sx={{ borderRadius: '15px' }}>
                                <Table size="small" aria-label="simple table">
                                    <TableHead>
                                        <TableRow key={'tableHeaders'}>
                                            <TableCell key={'name'}>Name</TableCell>
                                            <TableCell key={'userVPNip'}>User VPN IP</TableCell>
                                            <TableCell key={'organizationName'}>Organization Name</TableCell>
                                            <TableCell key={'email'} align="right">Email</TableCell>
                                            {vpnDeletePermission ?
                                                <TableCell key={'remove'} align="right"></TableCell>
                                                : null}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.users.map((obj) => (
                                            <TableRow
                                                key={obj._docId}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell key={`${obj._docId}-name`} component="th" scope="row">{_.toUpper(obj.user_name)}</TableCell>
                                                <TableCell key={`${obj._docId}-vpn_ipv4`} component="th" scope="row">{obj.vpn_ipv4}</TableCell>
                                                <TableCell key={`${obj._docId}-organization_name`} component="th" scope="row">{_.toUpper(obj.user_organization_name)}</TableCell>
                                                <TableCell key={`${obj._docId}-email`} align="right">{obj.user_email}</TableCell>
                                                {vpnDeletePermission ?
                                                    <TableCell key={`${obj._docId}-remove`} align="right">
                                                        <Tooltip title="Remove" placement="right">
                                                            <IconButton aria-label="delete"
                                                                color="error"
                                                                disabled={data.isEnabled ? false : true}
                                                                onClick={() => handleConfirmDeleteDialogOpen(obj.user_id)}
                                                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <RemoveCircleOutlineIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                    : null}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </BoxItem>
                    </CardContent>
                </Card>
            </Grid>
            <AddUserForm
                openDialogbox={createDialogState}
                handleClose={handleCreateDialogClose}
                id={_.has(data, "_docId") ? data._docId : '-'}
                refresh={refresh}
            />
            <ConfirmDelete key={deleteId}
                open={confirmDeleteDialogState}
                handleClose={handleConfirmDeleteDialogClose}
                handleDelete={deleteData}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snackbarOpen}
                onClose={handleSnakbarClose}
                autoHideDuration={2250}>
                {
                    snackbarOpen ?
                        <Alert severity={(message['status']) ? "success" : "error"} onClose={handleSnakbarClose}>
                            {
                                message['message']
                            }
                        </Alert>
                        : null
                }
            </Snackbar>
        </>
    )
}
