import _ from 'lodash';
import dayjs from 'dayjs';
// Mui
import { Box, Typography, Grid, CardContent, Paper } from "@mui/material";
// import PendingActionsIcon from '@mui/icons-material/PendingActions';
// Custom components
import StyledCard from "../../components/Card/StyledCard.component";

const WifiStatusCard = ({ profile }) => {

    const ssid = (_.has(profile, 'network.wifi.ssid') && !_.isEmpty(profile.network.wifi.ssid)) ? profile.network.wifi.ssid : '-';
    const signal = (_.has(profile, 'network.wifi.signal') && !_.isEmpty(profile.network.wifi.signal)) ? profile.network.wifi.signal : '-';
    const interfaceName = (_.has(profile, 'network.wifi.interfaceName') && !_.isEmpty(profile.network.wifi.interfaceName)) ? profile.network.wifi.interfaceName : '-';
    const ipv4 = (_.has(profile, 'network.wifi.ipv4') && !_.isEmpty(profile.network.wifi.ipv4)) ? profile.network.wifi.ipv4 : '-';
    const downloadedData = (_.has(profile, 'network.wifi.downloadedData') && !_.isEmpty(profile.network.wifi.downloadedData)) ? profile.network.wifi.downloadedData : '-';
    const downloadSpeed = (_.has(profile, 'network.wifi.downloadSpeed') && !_.isEmpty(profile.network.wifi.downloadSpeed)) ? profile.network.wifi.downloadSpeed : '-';
    const uploadedData = (_.has(profile, 'network.wifi.uploadedData') && !_.isEmpty(profile.network.wifi.uploadedData)) ? profile.network.wifi.uploadedData : '-';
    const uploadSpeed = (_.has(profile, 'network.wifi.uploadSpeed') && !_.isEmpty(profile.network.wifi.uploadSpeed)) ? profile.network.wifi.uploadSpeed : '-';
    const lastUpdated = (_.has(profile, 'utilization')) ? profile.utilization.timeStamp : '0';

    return (
        <StyledCard>
            <CardContent>
                <Typography variant="h5" color='primary.dark' component={Box} gutterBottom>
                    Wifi Status
                </Typography>

                <Grid container spacing={2} component={Paper} sx={{ p: 2, py: 4, position: 'relative' }}>
                    <Grid item xs={3}>
                        <Box>
                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>SSID</Typography>
                            <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{_.upperCase(ssid)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box>
                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>Interface Name</Typography>
                            <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{_.upperCase(interfaceName)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box>
                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>IPv4</Typography>
                            <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{ipv4}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box>
                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>Signal</Typography>
                            <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{`${signal}%`}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box>
                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>Downloaded Data</Typography>
                            <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{
                                `${downloadedData} bytes`
                            }</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box>
                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>Download Speed</Typography>
                            <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{
                                `${downloadSpeed} bytes/s`
                            }</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box>
                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>Uploaded Data</Typography>
                            <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{
                                `${uploadedData} bytes`
                            }</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box>
                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>Upload Speed</Typography>
                            <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{
                                `${uploadSpeed} bytes/s`
                            }</Typography>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={6}>
                        <Box>
                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>Device Connected</Typography>
                            <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>0</Typography>
                        </Box>
                    </Grid> */}
                    {_.has(profile, 'utilization') ?
                        <Box sx={{ color: 'white', display: 'flex', flexDirection: 'column', px: 1, borderRadius: 1, position: 'absolute', right: 8, bottom: 6, backgroundColor: '#B2B2B2' }}>
                            <Typography variant="caption">{`last updated on ${dayjs(lastUpdated).format('DD MMMM, YYYY h:mm:ss A')}`}</Typography>
                        </Box>
                        : null}
                </Grid>
            </CardContent>
        </StyledCard>
    );
}
export default WifiStatusCard;