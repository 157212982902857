// Routing
import { Routes, Route } from "react-router-dom";
import { getPermissionObject } from "../../helper/Utility.helper";
// Other Components
import RoleList from "../../sections/Roles/RoleList.section"
import CreateRole from "../../sections/Roles/CreateRole.section";
import RoleProfile from "../../sections/Roles/RoleProfile.section";
export default function Roles() {

    //permission decoding
    const permissionObject = getPermissionObject();
    const rolesCreatePermission = permissionObject.roles.create;

    return (
        <Routes>
            <Route path="/" element={<RoleList />} />
            <Route path="/create-role" element={rolesCreatePermission ? <CreateRole /> : null} />
            <Route path="/:_docId" element={<RoleProfile />} />
        </Routes>
    )
}