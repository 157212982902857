import _ from "lodash"
import { Card, CardHeader, Stack, CardContent, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { CardItem } from "./PermissionCardStyle";

const PermissionCard = ({ title, permissions, groupKey, handleEvent }) => (
    <Card sx={{ maxWidth: 345, height: '100%' }}>
        <CardHeader
            title={<Typography variant="h6" sx={{ color: '#808080' }}>
                {_.capitalize(title)}
            </Typography>}
        />
        <CardContent >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
            >
                {Object.keys(permissions).map((val) => (
                    <CardItem key={val}>
                        <FormControlLabel
                            label={val}
                            control={<Checkbox checked={permissions[val]} onChange={(e) => handleEvent(e, groupKey)} name={val} />}
                        />
                    </CardItem>
                ))}
            </Stack>
        </CardContent>
    </Card>
);

export default PermissionCard;