import { Routes, Route } from "react-router-dom";
import { getPermissionObject } from "../../helper/Utility.helper";
import { useSelector } from "react-redux";
// Custom Components
import OrganizationList from "../../sections/Organization/OrganizationList.section";
import CreateOrganization from "../../sections/Organization/CreateOrganization.section";
// import OrganizationProfile from "../../sections/Organization/OrganizationProfile.section";

export default function OrganizationPage() {

    //permission decoding
    const permissionObject = getPermissionObject();
    const organizationsCreatePermission = permissionObject.organizations.create;

    const organizationLevel = useSelector(state => state.authenticate.user.level);


    return (
        <Routes>
            <Route path="/" element={<OrganizationList />} />
            {/* <Route path="/:serial" element={<OrganizationProfile />} /> */}
            <Route path='/create-organization' element={organizationsCreatePermission && organizationLevel !== 3 ? <CreateOrganization /> : null} />
        </Routes>
    );
}