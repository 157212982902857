import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import axios from "axios";
import { getPermissionObject } from "../../helper/Utility.helper";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';

//Mui
import { Box, Container, IconButton, Typography } from "@mui/material";

// Material Icons
import EditIcon from '@mui/icons-material/Edit';

// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import DataTable from "../../components/DataTable/DataTable.component";
import { Invite, contextActions, actions } from "./Actions"
import { SearchQuery } from "../../components/Search/SearchQuery";
import EditUserNameDialog from "../../layouts/Users/EditUserNameDialog.layout";
import { ChangeUserRoleDialog } from "../../layouts/Users/ChangeUserRoleDialog.layout";

export default function UserList() {
    dayjs.extend(localizedFormat);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [userId, setUserId] = useState('');
    const [queryInput, setQueryInput] = useState("");
    const [toggleCleared, setToggleCleared] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [openEditUserNameDialog, setOpenEditUserNameDialog] = useState(false);
    const [openChangeUserRoleDialog, setOpenChangeUserRoleDialog] = useState(false);

    //permission decoding
    const permissionObject = getPermissionObject();
    const usersAddPermission = permissionObject.users.create;
    const usersEditPermission = permissionObject.users.update;

    const user = useSelector(state => state.authenticate.user);

    const columns = [
        {
            name: 'Name',
            selector: row => _.toUpper(row['name']),
            sortable: true
        },
        {
            name: usersEditPermission ? `Edit Name` : null,
            cell: row => (
                usersEditPermission ?
                    <>
                        <IconButton aria-label="delete" onClick={() => handleOpenEditUserNameChange(row['_docId'])}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </> : null
            ),
            minWidth: '70px',
            align: 'right'
        },
        {
            name: 'Email',
            selector: row => row['email'],
            sortable: true
        },
        {
            name: 'Organization',
            selector: row => row['organizationName'],
            cell: row =>
                <Box>
                    <Box >
                        <Typography>{_.toUpper(row['organizationName'])}</Typography>
                    </Box>
                    <Box >
                        <Typography variant="caption">{`Level : ${row['level']}`}</Typography>
                    </Box>
                </Box>,
            sortable: true
        },
        {
            name: 'Role',
            selector: row => _.toUpper(row['roleName']),
            sortable: true
        },
        {
            name: usersEditPermission ? "Change Role" : null,
            cell: row => (
                usersEditPermission ?
                    <IconButton
                        aria-label="edit"
                        disabled={user.name === row['name'] ? true : false}
                        onClick={() => handleOpenChangeUserRoleDialog(row['_docId'])}>
                        <EditIcon fontSize="small" />
                    </IconButton> : null
            ),
            minWidth: '70px',
            align: 'right'
        },

        {
            name: 'Created On',
            cell: row => dayjs(row['meta']['createdAt']).format('lll')
        }
    ]

    const getStoredRowsPerPage = () => {
        return Number(sessionStorage.getItem("usersTableRows")) || 5;
    };

    const [rowsPerPage, setRowsPerPage] = useState(getStoredRowsPerPage);

    // Store rows per page value in session storage when it changes
    const handlePerRowsChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        sessionStorage.setItem("usersTableRows", newRowsPerPage);
    };

    //Handle for User Name Change
    const handleOpenEditUserNameChange = (id) => {
        setOpenEditUserNameDialog(true);
        setUserId(id);
    }
    const handleCloseEditUserNameChange = () => setOpenEditUserNameDialog(false);

    //Handle for User Role Change
    const handleOpenChangeUserRoleDialog = (id) => {
        setOpenChangeUserRoleDialog(true);
        setUserId(id);
    }
    const handleCloseChangeUserRoleDialog = () => setOpenChangeUserRoleDialog(false);

    const getData = async () => {
        setLoading(true);
        const response = await axios.get('api/users/getAllUsers');
        const result = await response.data;
        setData(result.data);
        setLoading(false);
    }
    const handleNavigate = () => navigate('invite-user');
    const handleQueryInput = (event) => {
        let query = event.target.value.trim().toLowerCase();
        // console.log("query", query);
        setQueryInput(query);
    }
    const deleteAll = () => {
        const rows = selectedRows.map(r => r.serial);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, rows, 'serial'));
        }
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Box>
                {/* Sectoin Header */}
                <SectionHeader heading="Users" />

                <Box sx={{ flexGrow: 1, my: 2 }}>
                    <Container>
                        <DataTable
                            title={usersAddPermission ? <Invite handleNavigate={handleNavigate} /> : null}
                            columns={columns}
                            data={SearchQuery(queryInput, data, ['name', 'organizationName'])}
                            defaultSortField="serial"
                            defaultSortFieldId={1}
                            actions={actions({ handleNavigate, handleQueryInput, getData })}
                            contextActions={contextActions(deleteAll)}
                            onSelectedRowsChange={handleChange}
                            clearSelectedRows={toggleCleared}
                            progressPending={loading}
                            selectableRows={false}
                            selectableRowsHighlight={false}
                            paginationPerPage={rowsPerPage} // Use stored value
                            onChangeRowsPerPage={handlePerRowsChange}
                        />
                    </Container>
                </Box>
            </Box>

            <EditUserNameDialog
                key={userId}
                openDialog={openEditUserNameDialog}
                handleClose={handleCloseEditUserNameChange}
                name={userId ? data.find(obj => userId === obj._docId)?.name : '-'}
                id={userId}
                refresh={getData}
            />

            <ChangeUserRoleDialog
                key={userId}
                openDialog={openChangeUserRoleDialog}
                handleClose={handleCloseChangeUserRoleDialog}
                roleId={userId ? data.find(obj => userId === obj._docId)?.roleId : '-'}
                id={userId}
                refresh={getData}
            />

        </>
    );
}