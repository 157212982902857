import { useState } from "react";
import axios from "axios"
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Grid,
    TextField,
    Typography,
    Link, Snackbar,
    Alert
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';
import { styled } from '@mui/system';
import Copyright from '../../layouts/Authentication/Copyright.component';
import logo from '../../assets/ATRA_emblem.png';
import backgroundImage from '../../assets/sideBanner.jpg';

const BackgroundContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    display: 'block',
    overflow: 'hidden',
}));

const FormContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ImageContainer = styled('div')(({ theme }) => ({
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'left',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Logo = styled('img')(({ theme }) => ({
    width: '6em',
    marginBottom: theme.spacing(3),
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
    background: `-webkit-linear-gradient(-65deg, #ABD9E6 5%, #48A89D 27%, #134E5E 95%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    // paddingBottom: theme.spacing(6),
}));

const RegisteredEmail = () => {

    const error = useSelector(state => state.authenticate.error);
    // const isLoading = useSelector(state => state.authenticate.loading);

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleClose = () => {
        setOpen(false);
    }

    // const handleSubmit = () => {
    //     setLoading(true);
    //     if (message) {
    //         setLoading(false);
    //     }
    // }

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: yup.object().shape({
            email: yup
                .string()
                .email('Enter a valid email')
                .required('Email is required'),
        }),
        onSubmit: (formData) => {
            setIsLoading(true);
            postData(formData);
            setTimeout(() => {
                setIsLoading(false);
            }, 4000)
        }
    });

    const postData = async (data) => {
        const response = await axios.post('/auth/sendrecoverylink', data);
        const result = await response.data;
        setLoading(true);
        // console.log('Install response', response.status);
        setOpen(true);
        setMessage(result);
        if (result.status) {
            setTimeout(() => {
                navigate('/login', { replace: true });
            }, 16000);
        }
        setLoading(false);
    }

    return (
        <>
            <BackgroundContainer>
                {/*<Container maxWidth="lg">*/}
                <Grid container >
                    <Grid item xs={12} md={12} lg={5} xl={5}>
                        <form onSubmit={formik.handleSubmit}>
                            <FormContainer>
                                <Logo src={logo} alt="Logo" />
                                <GradientTypography component="div" variant="h5" align="center">
                                    {/* {'प्रवेशः।'} */}
                                    {`Registered Email`}
                                </GradientTypography>
                                <Typography sx={{ pb: 6 }} variant="caption" align="center" color="textSecondary">
                                    For recovering your password
                                </Typography>

                                <Box sx={{ width: '50%' }}>
                                    <Box sx={{ mb: 3 }}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            label="Email"
                                            id="email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && Boolean(formik.errors.email)}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>

                                </Box>

                                <Box sx={{ mt: 0, width: '50%' }}>

                                    <LoadingButton
                                        variant="contained"
                                        size="small"
                                        type="submit"
                                        // onClick={handleSubmit}
                                        sx={{
                                            mt: 4,
                                            width: '100%'
                                        }}
                                        endIcon={<LoginIcon />}
                                        loading={isLoading}
                                    // disabled={true}
                                    >
                                        Send Recovery Link
                                    </LoadingButton >

                                </Box>

                                <Grid container justifyContent="center" sx={{ mt: 4 }}>
                                    <Grid item>
                                        <Typography sx={{ cursor: 'pointer' }} variant="body2" align="center" color="textSecondary">
                                            Back to{' '}
                                            <Link onClick={() => navigate('/login', { replace: true })} underline="always">
                                                Sign In
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center" sx={{ mt: 2 }}>
                                    <Grid item>
                                        <Typography sx={{ cursor: 'pointer' }} variant="body2" align="center" color="textSecondary">

                                            <Link href={`https://atreyo.in/en/contact-us`} target='_blank' underline="always">
                                                Contact Us
                                            </Link>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Copyright
                                    sx={{ mt: 2 }}
                                />
                                {
                                    (error && error.message !== "") ?
                                        <Grid container justifyContent="center" sx={{ mt: 2 }}>
                                            <Grid item>
                                                <Alert severity="error">
                                                    {error.message}
                                                </Alert>
                                            </Grid>
                                        </Grid>
                                        : null
                                }
                            </FormContainer>
                        </form>
                    </Grid>
                    <Grid item xs={12} md={7} sx={{ padding: 0, margin: '0px' }}>
                        <ImageContainer />
                    </Grid>
                </Grid>
                {/*</Container>*/}
            </BackgroundContainer>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleClose}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </>
    );
};


export default RegisteredEmail;
