import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAid } from "../../helper/Utility.helper";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";

// MUI Components
import { Box, Button, Stack, TextField, Snackbar, Alert, Paper, MenuItem, Divider, Typography } from "@mui/material";

// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import Loading from "../../components/Common/Loading.Component";
import { BoxItem } from "../../components/Common/Item.component";
import addDevices from "../../assets/addDevices.png"

const validationSchema = Yup.object().shape({
    name: Yup.string('Must be string').required("Name is required"),
    serial: Yup.string('Must be string').required("Serial is required"),
    organizationId: Yup.string('Must be string').required("Organization is required"),
    // description: Yup.string('special charactors are not allowed')
});

export default function AddDevices() {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const user = useSelector(state => state.authenticate.user);
    const aid = getAid();
    const session = jwtDecode(aid);

    const formik = useFormik({
        initialValues: { name: '', serial: '', organizationId: '' },
        validationSchema: validationSchema,
        onSubmit: (data) => {
            postData(data);
            // console.log(data);
        }
    })

    const handleReset = () => {
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        navigate('/devices', { replace: true });
    }

    const getOrganizationList = async () => {
        setLoading(true);
        const response = await axios.get('api/organization');
        const result = await response.data;
        setOrganizationList(result.data);
        if (!_.isEmpty(result)) setLoading(false);
    }

    const postData = async (data) => {
        const response = await axios.post('api/devices/install', data);
        const result = await response.data;
        // console.log('Install response', response.status);
        setOpen(true);
        setMessage(result);
        if (result.status) {
            setTimeout(() => {
                navigate('/devices', { replace: true });
            }, 4000);
        }
    }

    useEffect(() => {
        if (user.level !== 3) {
            getOrganizationList();
        }
    }, []);

    return (
        <Box sx={{ width: "screen" }}>
            <SectionHeader heading="Add New Devices" />

            <Box sx={{ flexGrow: 1, mt: 4 }}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}>
                    <Paper elevation={4} sx={{ borderRadius: '15px', padding: 2, width: 450 }}>
                        <BoxItem>
                            <img src={addDevices} width="150px" height="auto" alt="Role_icon" />
                            <TextField
                                fullWidth
                                margin="normal"
                                size="small"
                                variant="outlined"
                                id="name"
                                name="name"
                                label="Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.errors.name && formik.touched.name}
                                helperText={formik.errors.name}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                size="small"
                                variant="outlined"
                                id="serial"
                                name="serial"
                                label="Serial"
                                value={formik.values.serial}
                                onChange={formik.handleChange}
                                error={formik.errors.serial && formik.touched.serial}
                                helperText={formik.errors.serial}
                            />
                            <TextField
                                fullWidth
                                select
                                margin="normal"
                                size="small"
                                name="organizationId"
                                label="Select Organization"
                                id="organizationId"
                                value={formik.values.organizationId}
                                onChange={formik.handleChange}
                                error={formik.errors.organizationId && formik.touched.organizationId}
                                helperText={formik.errors.organizationId}
                            >
                                <MenuItem value={"current"}>{_.toUpper(session.organizationName)}</MenuItem>
                                <Divider />
                                {loading ? (<Loading />) : organizationList.length !== 0 ? (organizationList.map((obj, index) =>
                                    (<MenuItem key={obj._docId} value={obj._docId}>{_.toUpper(obj.name)}</MenuItem>))) :
                                    (<Typography sx={{ ml: 3 }} color="primary">Child organizations are not available</Typography>)
                                }
                            </TextField>
                            {/* <TextField
                                fullWidth
                                multiline
                                margin="normal"
                                rows={4}
                                size="small"
                                label="Description"
                                id="description"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            /> */}
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="outlined" onClick={handleReset}>Reset</Button>
                                <Button variant="contained" type="submit" onClick={formik.handleSubmit}>Add</Button>
                                <Button variant="outlined" color="error" onClick={handleClose}> Cancel </Button>
                            </Box>
                        </BoxItem>
                    </Paper>
                </Stack>
            </Box>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleClose}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    );
}