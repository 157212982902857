// Utility
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

// Routing
import { Routes, Route } from "react-router-dom";
// Material UI Components
import { Box } from "@mui/material";

import { getAid } from "../../helper/Utility.helper";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import { useSelector } from "react-redux";

// Component
import Profile from "../Profile/Profile.page";
import OverviewPage from "../Overview/OverviewMain.page";
import DevicesMain from "../Devices/DevicesRoute.page";
// import WebConnect from "../WebConnect/WebConnectRoute.page";
import UsersPage from "../Users/UsersRoute.page";
// import CompaniesPage from '../Companies/List.page';
import OrganizationPage from '../Organizations/OrganizationRoute.page';
import Roles from "../Roles/RolesRoute.page";
import VPNMain from "../VPN/VPNRoute.page";

import Main from "../../layouts/Dashboard/Main.layout";

export default function MainDashboard() {
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);

    const user = useSelector(state => state.authenticate.user);
    const userLevel = user.level;

    //permission decoding
    const aid = getAid();
    if (!aid) return false;
    const session = jwtDecode(aid);
    const permissionString = session.permissions
    const permissionObject = JSON.parse(atob(permissionString));

    function getViewPermissions(permission) {
        return Object.keys(permission).filter(key => permission[key].view);
    }
    const viewPermissions = getViewPermissions(permissionObject);

    return (
        <Box sx={{ display: 'flex' }}>
            <Main />
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 12 }}>
                <Routes>
                    <Route path="/" element={<OverviewPage />} />
                    <Route path="devices/*" element={viewPermissions.includes("devices") ? <DevicesMain /> : null} />
                    <Route path="profile" element={<Profile />} />
                    {/* <Route path="remote/*" element={permissionArray.includes("remoteConnection") || permissionArray.includes("remote") ? <WebConnect /> : null} /> */}
                    <Route path="vpn/*" element={viewPermissions.includes("vpn") ? <VPNMain /> : null} />
                    <Route path="users/*" element={viewPermissions.includes("users") ? <UsersPage /> : null} />
                    <Route path="organizations/*" element={viewPermissions.includes("organizations") && userLevel !== 3 ? <OrganizationPage /> : null} />
                    <Route path="roles/*" element={viewPermissions.includes("roles") ? <Roles /> : null} />
                </Routes>
            </Box>
        </Box>
    );
}
