import { useState, useEffect } from "react";
import axios from "axios";
import _ from 'lodash'

// @Mui
import {
    FormGroup, Box, FormControlLabel, Button, Snackbar, Alert, Typography,
    Dialog, DialogTitle, FormHelperText, FormControl, IconButton, DialogContent, Checkbox
} from '@mui/material';

// @Mui-icons
import CloseIcon from '@mui/icons-material/Close';

// Custom Components
import Loading from "../../components/Common/Loading.Component";

export default function AddUserForm({ openDialogbox, handleClose, id, refresh }) {

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState({});
    const [message, setMessage] = useState("");
    const [userList, setUserList] = useState([]);
    const [users, setUsers] = useState([]);

    const handleSelectUsers = (e) => {
        setError({ users: false })
        const value = e.target.value;
        const isChecked = e.target.checked;
        if (isChecked) {
            setUsers([...users, value]);
        } else {
            setUsers(users.filter(item => item !== value));
        }
    }

    const usersValidation = () => {
        if (users.length > 0) {
            return users.every(user => {
                const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
                const result = regex.test(user);
                result ? setError({ users: false }) : setError({ users: true });
                return result
            })
        } else {
            setError({ users: true })
        }
    }

    const handleReset = () => {
        setUsers([]);
        setError({});
    }

    const handleCloseSnackbar = () => {
        setOpen(false);
    }

    const handleAddUsersToVPNTunnel = async () => {
        if (usersValidation()) {
            const formObject = { users, tunnelId: id };
            // console.log(formObject);
            await postData(formObject);
            handleReset();
        }
    }

    const getAllUsersData = async () => {
        setLoading(true);
        const response = await axios.get(`api/vpn/getvpnuserlisttype2?vpnTunnelId=${id}`);
        const result = await response.data;
        setUserList(result.data);
        setLoading(false);
    }

    const postData = async (data) => {
        axios.post('api/vpn/add_users', data).then(res => {
            const result = res.data;
            handleClose();
            setMessage(result);
            setOpen(true);
        }).catch(err => {
            handleClose();
            setMessage(err.response.data);
            setOpen(true);
        });
    }

    useEffect(() => {
        getAllUsersData();
        return () => handleReset()
    }, []);

    useEffect(() => {
        if (!_.isEmpty(message) && !open) refresh();
    }, [message, open]);


    return (
        <Box >
            <Dialog
                open={openDialogbox}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Add User</DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[800],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent >
                    {/* User List */}
                    <FormControl fullWidth>
                        <Box sx={{ height: 160, overflowY: 'auto' }}>
                            {error.users ? < FormHelperText error > Select user</FormHelperText> : null}
                            {
                                (loading) ? <Loading /> :
                                    userList.length !== 0 ?
                                        <FormGroup>
                                            {
                                                userList.map((obj, index) => (
                                                    <FormControlLabel key={obj._docId}
                                                        control={<Checkbox checked={users.includes(obj._docId)} name="allUsersData" value={obj._docId} onChange={handleSelectUsers} />}
                                                        label={_.toUpper(obj.name)}
                                                    />

                                                ))
                                            }
                                        </FormGroup>
                                        :
                                        <Typography sx={{ ml: 3 }} color="primary">Data Not Available</Typography>
                            }
                        </Box>
                    </FormControl>

                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="outlined" onClick={handleReset}>
                            Reset
                        </Button>
                        <Button onClick={handleAddUsersToVPNTunnel} variant="contained">
                            Add User
                        </Button>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Box>
                </DialogContent>

            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleCloseSnackbar}
                autoHideDuration={2250}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleCloseSnackbar}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    )
}