import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NavigationComponent2 = ({ id, name }) => {
    const navigate = useNavigate();
    return (
        <Typography variant="subtitle1" onClick={() => navigate(id)} sx={{ '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }}>
            {name}
        </Typography>
    );
}
export default NavigationComponent2;