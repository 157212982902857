import { styled } from "@mui/material/styles";
import { Box, Typography } from '@mui/material';

// welcome card style

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const GradientTypography = styled(Typography)(({ theme }) => ({
    // background: `-webkit-linear-gradient(-65deg, #ABD9E6 5%, #48A89D 27%, #134E5E 95%)`,
    background: `-webkit-linear-gradient(#48A89D 27%, #134E5E 95%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    // paddingBottom: theme.spacing(6),
}));

export { Item, GradientTypography }