import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAid } from "../../helper/Utility.helper";
import jwtDecode from "jwt-decode";
import _ from "lodash";

// Material Icons

import {
    List, Divider
} from "@mui/material";

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from "@mui/material/styles"

import { DashoardItems, ManageItems } from "./NavItems.component";


const StyledListItem = styled(ListItem)(({ theme, selected, open }) => ({
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: theme.spacing(2.5),
    // backgroundColor: selected ? theme.palette.action.selected : 'inherit',
}));


export default function Navlist(props) {
    const [selectedIndex, setSelectedIndex] = useState('overview');
    const navigate = useNavigate();
    const user = useSelector(state => state.authenticate.user);
    const userLevel = user.level;
    // console.log('userLevel', userLevel)

    //permission decoding
    const aid = getAid();
    if (!aid) return false;
    const session = jwtDecode(aid);
    const permissionString = session.permissions
    const permissionObject = JSON.parse(atob(permissionString));

    function getViewPermissions(permission) {
        return Object.keys(permission).filter(key => permission[key].view);
    }
    const viewPermissions = getViewPermissions(permissionObject);
    const filteredArray = viewPermissions.filter((x) => x !== "organizations");

    const handleListItemClick = (event, index, path) => {
        navigate(path);
        setSelectedIndex(index);
    }
    return (
        <>
            <List>
                {

                    DashoardItems.map((text, index) => (
                        (viewPermissions.includes(_.lowerCase(text.id))) || (text.id === "overview") ?
                            <StyledListItem
                                key={text.id}
                                // button
                                disablePadding
                            // onClick={(event) => handleListItemClick(event, text.id, text.path)}
                            // selected={selectedIndex === text.id}
                            >
                                <ListItemButton
                                    onClick={(event) => handleListItemClick(event, text.id, text.path)}
                                    selected={selectedIndex === text.id}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: props.open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: props.open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {text.icon}
                                    </ListItemIcon>

                                    <ListItemText primary={text.title} sx={{ opacity: props.open ? 1 : 0 }}
                                        primaryTypographyProps={{
                                            // variant: 'subtitle2',
                                            style: {
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }
                                        }}
                                    />
                                </ListItemButton>
                            </StyledListItem> : null
                        // </ListItem>
                    ))
                }
            </List >
            <Divider />
            <List>
                {

                    ManageItems.map((text, index) => (

                        userLevel !== 3 ?
                            viewPermissions.includes(_.lowerCase(text.id)) ?
                                <StyledListItem
                                    key={text.id}
                                    // disablePadding sx={{ display: 'block' }}
                                    // onClick={() => navigate(text.path)}
                                    disablePadding
                                >
                                    <ListItemButton
                                        onClick={(event) => handleListItemClick(event, text.id, text.path)}
                                        selected={selectedIndex === text.id}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: props.open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: props.open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {text.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={text.title} sx={{ opacity: props.open ? 1 : 0 }}
                                            primaryTypographyProps={{
                                                // variant: 'subtitle2',
                                                style: {
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }
                                            }}
                                        />
                                    </ListItemButton>
                                </StyledListItem> : null
                            :
                            filteredArray.includes(_.lowerCase(text.id)) ?
                                <StyledListItem
                                    key={text.id}
                                    // disablePadding sx={{ display: 'block' }}
                                    // onClick={() => navigate(text.path)}
                                    disablePadding
                                >
                                    <ListItemButton
                                        onClick={(event) => handleListItemClick(event, text.id, text.path)}
                                        selected={selectedIndex === text.id}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: props.open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: props.open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {text.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={text.title} sx={{ opacity: props.open ? 1 : 0 }}
                                            primaryTypographyProps={{
                                                // variant: 'subtitle2',
                                                style: {
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }
                                            }}
                                        />
                                    </ListItemButton>
                                </StyledListItem> : null


                    ))
                }
            </List >
        </>
    );
}