import { Routes, Route } from "react-router-dom";
import { getPermissionObject } from "../../helper/Utility.helper";
import VPNList from "../../sections/VPN/VPNList.section";
import VPNProfile from "../../sections/VPN/VPNProfile.section";
import CreateVPNTunnel from "../../sections/VPN/CreateVPNTunnel.section";

export default function VPNMain() {

    //permission decoding
    const permissionObject = getPermissionObject();
    const vpnCreatePermission = permissionObject.vpn.create;

    return (
        <Routes>
            <Route path="/" element={<VPNList />} />
            <Route path="/:_docId" element={<VPNProfile />} />
            <Route path='/create-vpn-tunnel' element={vpnCreatePermission ? <CreateVPNTunnel /> : null} />
        </Routes>
    )
}