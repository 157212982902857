import { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import { getPermissionObject } from "../../helper/Utility.helper";

// Mui
import {
    Box, Typography, Divider, IconButton, Grid, Card, CardHeader, CardContent, Snackbar, Alert,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// Custom Component
import { BoxItem } from "../../components/Common/Item.component";
import AddDevice from "../../sections/VPN/AddDeviceForm.section";
import ConfirmDelete from "../Delete.layout";

export default function VPNTunnelDeviceListCard({ tunnelId, vpnProfile, refresh, data }) {

    const [createDialogState, setCreateDialogState] = useState(false);
    //permission decoding
    const permissionObject = getPermissionObject();
    const vpnCreatePermission = permissionObject.vpn.create;
    const vpnDeletePermission = permissionObject.vpn.delete;
    const [tooltipTitle, setTooltipTitle] = useState('Copy');
    const [copiedText, copyToClipboard] = useCopyToClipboard();
    const [confirmDeleteDialogState, setConfirmDeleteDialogState] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState(null);

    const handleCreateDialogOpen = () => setCreateDialogState(true);
    const handleCreateDialogClose = () => {
        setCreateDialogState(false)
    };

    const handleConfirmDeleteDialogOpen = (id) => {
        setConfirmDeleteDialogState(true);
        setDeleteId(id);
    }
    const handleConfirmDeleteDialogClose = () => setConfirmDeleteDialogState(false);
    const handleSnakbarClose = () => setSnackbarOpen(false);

    const deleteData = async () => {
        const response = await axios.delete(`/api/vpn/removedevice`, { data: { deviceId: deleteId, tunnelId: data._docId } });
        const result = await response.data;
        setMessage(result);
        setSnackbarOpen(true);
        setConfirmDeleteDialogState(false);
    }

    useEffect(() => {
        if (!_.isEmpty(message) && !snackbarOpen) refresh();
    }, [message, snackbarOpen]);

    return (
        <>
            <Grid item >
                <Card elevation={4} sx={{ maxHeight: 540, display: 'flex', flexDirection: 'column', borderRadius: '15px', backgroundColor: '#FAFAFA', overflowY: 'scroll' }}>
                    <CardHeader
                        title="Device List"
                        action={vpnCreatePermission && !data.isActive && data.isEnabled ?
                            <Tooltip title="Add Device" placement="left">
                                <IconButton variant="contained"
                                    onClick={handleCreateDialogOpen}
                                    aria-label="Add Device"
                                    color="warning"
                                >
                                    <AddCircleRoundedIcon />
                                </IconButton>
                            </Tooltip>
                            : null}
                    />
                    <CardContent sx={{ pt: 0, flexGrow: 1 }}>
                        <BoxItem sx={{ textAlign: 'left', pt: 0, backgroundColor: 'inherit' }}>

                            {vpnProfile.map((obj) => (
                                <Accordion key={obj.device_serial} expanded={obj.network_forwarding ? undefined : false} sx={{ backgroundColor: '#FAFAFA' }} elevation={0} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon color={obj.network_forwarding ? undefined : "disabled"} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Box >
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
                                                <Typography variant="subtitle1" >{_.toUpper(obj.device_name)}</Typography>
                                                {vpnDeletePermission ?
                                                    <Tooltip title="Remove" placement="right">
                                                        <IconButton aria-label="delete"
                                                            color="error"
                                                            disabled={data.isEnabled ? false : true}
                                                            onClick={() => handleConfirmDeleteDialogOpen(obj.device_id)}
                                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <RemoveCircleOutlineIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    : null}
                                            </Box>

                                            <Typography variant="subtitle1" >Model : {_.toUpper(obj.device_model)}</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant="caption">Local IP : {obj.device_local_ipv4}</Typography>
                                                    <Typography variant="caption">
                                                        VPN IP : {obj.device_vpn_ipv4}
                                                        <Tooltip title={tooltipTitle} placement="right">
                                                            <IconButton onClick={() => {
                                                                copyToClipboard(obj.device_vpn_ipv4)
                                                                setTooltipTitle('Copied');
                                                                setTimeout(() => {
                                                                    setTooltipTitle('');
                                                                }, 500);
                                                            }} size="small">
                                                                <ContentCopyIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Typography>
                                                </Box>
                                                <Divider sx={{ marginInline: 4 }} orientation="vertical" flexItem />
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                    <Typography variant="caption">Network Forwarding</Typography>
                                                    <Typography variant="caption">Status : {obj.network_forwarding ? "Enabled" : "Disable"}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer sx={{ borderRadius: '15px' }}>
                                            <Table size="small" aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>IP </TableCell>
                                                        <TableCell align="right">Node</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {obj.routing_ip_list.map((i, n) => (
                                                        <TableRow
                                                            key={n + 1}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">{i}</TableCell>

                                                            <TableCell align="right">Node {n + 1} </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </BoxItem>
                    </CardContent>
                </Card>
            </Grid>
            <AddDevice
                openDialogbox={createDialogState}
                handleClose={handleCreateDialogClose}
                tunnelId={tunnelId}
                refresh={refresh}
            />
            <ConfirmDelete key={deleteId}
                open={confirmDeleteDialogState}
                handleClose={handleConfirmDeleteDialogClose}
                handleDelete={deleteData}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snackbarOpen}
                onClose={handleSnakbarClose}
                autoHideDuration={2250}>
                {
                    snackbarOpen ?
                        <Alert severity={(message['status']) ? "success" : "error"} onClose={handleSnakbarClose}>
                            {
                                message['message']
                            }
                        </Alert>
                        : null
                }
            </Snackbar>
        </>
    )
}