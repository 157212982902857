import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import * as Yup from "yup";
import { getAid } from "../../helper/Utility.helper";
import jwtDecode from "jwt-decode";
import { getDefaultTimezone } from "../../helper/Utility.helper";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

// MUI Components
import {
    Box, Button, Stack, TextField, Snackbar, Alert, Paper, MenuItem, Divider,
    Typography
} from "@mui/material";
// import LoginIcon from '@mui/icons-material/Login';
import LoadingButton from '@mui/lab/LoadingButton';

// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import { BoxItem } from "../../components/Common/Item.component";
import Loading from "../../components/Common/Loading.Component";

const validationSchema = Yup.object().shape({
    name: Yup.string('Name must be valid').required("Name is required"),
    email: Yup.string('Email must be valid').required("Email is required"),
    organizationId: Yup.string('Must be organization Id').required("organization Id is required"),
    roleId: Yup.string('Must be roleId').required("roleId  is required"),
    zoneInfo: Yup.string('Zone must be valid').required("Zone is required")
});

export default function InviteUsers() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector(state => state.authenticate.user);
    const aid = getAid();
    const session = jwtDecode(aid);

    const formik = useFormik({
        initialValues: { name: '', email: '', organizationId: '', roleId: '', zoneInfo: getDefaultTimezone() },
        validationSchema: validationSchema,
        onSubmit: (data) => {
            setIsLoading(true)
            postData(data);
            // console.log(data);
            setTimeout(() => {
                setIsLoading(false);
            }, 4000)
        }
    })

    const handleReset = () => {
        formik.resetForm();
    };

    const handleClose = () => {
        setOpen(false);
        navigate('/users', { replace: true });
    }

    const getOrganizationList = async () => {
        setLoading(true);
        const response = await axios.get('api/organization');
        const result = await response.data;
        setOrganizationList(result.data);
        if (!_.isEmpty(result)) setLoading(false);
    }

    const getData = async () => {
        setLoading(true);
        const response = await axios.get('api/roles');
        const result = await response.data;
        setData(result.data);
        setLoading(false);
    }

    const postData = async (data) => {
        const response = await axios.post('/api/users/invite', data);
        const result = await response.data;
        // console.log('Install response', response.status);
        setOpen(true);
        setMessage(result);
        if (result.status) {
            setTimeout(() => {
                navigate('/users', { replace: true });
            }, 4000);
        }
    }

    useEffect(() => {
        getData();
        if (user.level !== 3) {
            getOrganizationList();
        }
    }, []);

    return (
        <Box sx={{ width: "screen" }}>
            <SectionHeader heading="Invite New User" />
            <Box sx={{ flexGrow: 1, mt: 4 }}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}>
                    <Paper elevation={4} sx={{ borderRadius: '15px', padding: 2, width: 450 }}>
                        <BoxItem>
                            {/* <img src={addDevices} width="150px" height="auto" alt="Role_icon" /> */}
                            <TextField
                                fullWidth
                                margin="normal"
                                size="small"
                                variant="outlined"
                                id="name"
                                name="name"
                                label="Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.errors.name && formik.touched.name}
                                helperText={formik.errors.name}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                size="small"
                                variant="outlined"
                                id="email"
                                name="email"
                                label="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.errors.email && formik.touched.email}
                                helperText={formik.errors.email}
                            />
                            <TextField
                                fullWidth
                                margin="normal"
                                select
                                size="small"
                                label="Organization"
                                name="organizationId"
                                value={formik.values.organizationId}
                                onChange={formik.handleChange}
                                error={formik.errors.organizationId && formik.touched.organizationId}
                                helperText={formik.errors.organizationId}
                            >
                                <MenuItem value={"current"}>{_.toUpper(session.organizationName)}</MenuItem>
                                <Divider />
                                {loading ? (<Loading />) : organizationList.length !== 0 ? (organizationList.map((obj, index) =>
                                    (<MenuItem key={obj._docId} value={obj._docId}>{_.toUpper(obj.name)}</MenuItem>))) :
                                    (<Typography sx={{ ml: 3 }} color="primary">Child organizations are not available</Typography>)
                                }
                            </TextField>
                            <TextField
                                fullWidth
                                margin="normal"
                                select
                                size="small"
                                label="Role"
                                name="roleId"
                                value={formik.values.roleId}
                                onChange={formik.handleChange}
                                error={formik.errors.roleId && formik.touched.roleId}
                                helperText={formik.errors.roleId}
                            >
                                {
                                    (loading) ? <Loading /> : data.length !== 0 ?
                                        (data.map((obj, index) => <MenuItem key={obj._docId} value={obj._docId}>{_.toUpper(obj.name)}</MenuItem>)) :
                                        (<Typography sx={{ ml: 3 }} color="primary">Roles are not available</Typography>)
                                }
                            </TextField>
                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="outlined" onClick={handleReset}>Reset</Button>
                                <LoadingButton
                                    variant="contained"
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                    // endIcon={<LoginIcon />}
                                    loading={isLoading}
                                >
                                    Invite
                                </LoadingButton>
                                <Button variant="outlined" color="error" onClick={handleClose}> Cancel </Button>
                            </Box>
                        </BoxItem>
                    </Paper>
                </Stack>
            </Box>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleClose}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    );
}