import { useMemo } from "react";

function SearchQuery(queryString, data, columns) {
    return useMemo(() => {
        const query = queryString.toLowerCase();

        return data.filter(f =>
            columns.some(item => f[item]?.toLowerCase().includes(query))
        );
    }, [queryString, data, columns]); // Recompute only when inputs change
}

export { SearchQuery };