import _ from "lodash";
import { Box, Grid } from "@mui/material";

// Custom Component
import DeviceMediaCard from "./DeviceMediaCard.layout";
import GeneralCard from "./GeneralCard.layout";
import ResourceCard from "./ResourceCard.layout";
import WifiStatusCard from "./WifiCard.component";
import EthernetStatusCard from "./EthernetCard.layout";
import CellularCard from "./CellularCard.layout";

export default function Overview({ profile, loading }) {
    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={3} xl={3} >
                    {
                        (!_.isEmpty(profile)) ?
                            <DeviceMediaCard profile={profile} loading={loading} />
                            : null
                    }
                </Grid>
                <Grid item xs={12} lg={9} xl={9} >
                    {/* <Box sx={{ width: '100%', height: 'calc(100vh - 200px)', overflowY: 'scroll' }}> */}
                    {
                        (!_.isEmpty(profile)) ?
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={12} xl={12}>
                                    <GeneralCard profile={profile} loading={loading} />
                                </Grid>
                                {
                                    <Grid item xs={12} lg={12} xl={12}>
                                        <ResourceCard profile={profile} loading={loading} />
                                    </Grid>
                                }

                                {
                                    (_.has(profile, 'network.lan') && !_.isEmpty(profile.network.lan)) ?
                                        <Grid item xs={12} lg={12} xl={12}>
                                            <EthernetStatusCard profile={profile} loading={loading} />
                                        </Grid>
                                        : null
                                }
                                {
                                    (_.has(profile, 'network.wifi') && !_.isEmpty(profile.network.wifi)) ?
                                        <Grid item xs={12} lg={12} xl={12}>
                                            <WifiStatusCard profile={profile} />
                                        </Grid>
                                        : null
                                }
                                {
                                    (_.has(profile, 'network.cellular') && !_.isEmpty(profile.network.cellular)) ?
                                        <Grid item xs={12} lg={12} xl={12}>
                                            <CellularCard profile={profile} />
                                        </Grid>
                                        : null
                                }
                            </Grid>
                            : null
                    }
                    {/* </Box> */}
                </Grid>
            </Grid>
        </Box>
    );
}