import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useSelector } from "react-redux";
import { getPermissionObject } from "../../helper/Utility.helper";

//Mui
import { Box, Container, } from "@mui/material";

// Material Icons
// import InfoIcon from '@mui/icons-material/Info';

// Custom Components
// import ParentOrganizationBasicInfoCard from "../../layouts/Organization/ParentOrganizationBasicInfoCard.layout";
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import DataTable from "../../components/DataTable/DataTable.component";
import { actions, Create, contextActions } from "./Action";
import { SearchQuery } from "../../components/Search/SearchQuery";
import Loading from "../../components/Common/Loading.Component"
// import NavigationComponent2 from "../../components/Common/NavigateComponent2.component";

const columns = [
    {
        name: 'Name',
        sortable: true,
        selector: row => _.toUpper(row['name']),
        // cell: row =>
        //     <Box>
        //         <Box >
        //             <NavigationComponent2 id={row._docId} name={_.toUpper(row.name)} />
        //             {/* <Typography>{row['name']}</Typography> */}
        //         </Box>
        //         <Box >
        //             <Typography variant="caption" color={'#757575'}>Level : {row['level']}</Typography>
        //         </Box>
        //     </Box>
    },
    {
        name: 'Device Count',
        selector: row => row['deviceCount'],
    },
    {
        name: 'Created By',
        selector: row => _.toUpper(row['createdBy']),
        sortable: true,
    },
    {
        name: 'Created At',
        selector: row => row['createdAt'],
        sortable: true,
        cell: row => dayjs(row['createdAt']).format('lll')
    }
];

export default function OrganizationList() {

    dayjs.extend(localizedFormat);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [queryInput, setQueryInput] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    //permission decoding
    const permissionObject = getPermissionObject();
    const organizationsCreatePermission = permissionObject.organizations.create;

    const organizationLevel = useSelector(state => state.authenticate.user.level);

    const getStoredRowsPerPage = () => {
        return Number(sessionStorage.getItem("orgTableRows")) || 5;
    };

    const [rowsPerPage, setRowsPerPage] = useState(getStoredRowsPerPage);

    // Store rows per page value in session storage when it changes
    const handlePerRowsChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        sessionStorage.setItem("orgTableRows", newRowsPerPage);
    };

    const deleteAll = () => {
        const rows = selectedRows.map(r => r.name);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, rows, 'name'));
        }
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    const handleQueryInput = (e) => {
        const query = e.target.value.trim().toLowerCase();
        setQueryInput(query);
    }
    const handleNavigate = () => {
        navigate('create-organization');
    }

    // GET Request to get the list of companies
    const getData = async () => {
        setLoading(true);
        const response = await axios.get('api/organization');
        const result = await response.data;
        setData(result.data);
        setLoading(false);
    }
    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <SectionHeader heading="Organizations" />

            {/* <Box sx={{ my: 4 }}>
                {loading ? <Loading /> :
                    <ParentOrganizationBasicInfoCard
                        organizationName={(!_.isEmpty(data[0])) ? _.toUpper(data[0].name) : "-"}
                        organizationLevel={(!_.isEmpty(data[0])) ? data[0].level : "-"}
                    />
                }
            </Box> */}

            <Box sx={{ flexGrow: 1, my: 2 }}>
                <Container>
                    {organizationsCreatePermission && organizationLevel !== 3 ?
                        <Create handleNavigate={handleNavigate} /> : null}
                    {data.length < 0 ? <Loading /> :
                        <DataTable
                            title={"Child Organizations"}
                            columns={columns}
                            data={SearchQuery(queryInput, data, ['name'])}
                            defaultSortField="name"
                            defaultSortFieldId={1}
                            actions={actions({ handleQueryInput, getData })}
                            contextActions={contextActions(deleteAll)}
                            onSelectedRowsChange={handleChange}
                            clearSelectedRows={toggleCleared}
                            progressPending={loading}
                            selectableRows={false}
                            selectableRowsHighlight={false}
                            paginationPerPage={rowsPerPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                        />
                    }
                </Container>
            </Box>
        </>
    )
}