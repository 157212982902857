import { useEffect, useState } from 'react';
import axios from 'axios';
import { getPermissionObject } from "../../helper/Utility.helper";
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
// @mui
import { Box, Button, Card, Grid, Alert, Typography, CardContent, Snackbar, IconButton, Tooltip, Link } from '@mui/material';
import _ from 'lodash';
import SyncIcon from '@mui/icons-material/Sync';
import LaunchIcon from '@mui/icons-material/Launch';

export default function VPNTunnelBasicInfoCard({ vpnProfile, onDelete, refresh }) {

    //permission decoding
    const permissionObject = getPermissionObject();
    const vpnDeletePermission = permissionObject.vpn.delete;
    const vpnStartStopPermission = permissionObject.vpn.startStop;
    const vpnEnableDisablePermission = permissionObject.vpn.enableDisable;
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState({});
    const user = useSelector(state => state.authenticate.user);
    const userNames = vpnProfile.users.map(user => user.user_name);

    const handleStartStop = async () => {
        const response = await axios.put(`/api/vpn/changeactivestatus?vpnTunnelId=${vpnProfile._docId}`);
        const result = await response.data;
        setOpen(true);
        setMessage(result);
    }

    const handleEnableDisable = async () => {
        const response = await axios.put(`/api/vpn/changeeligibilitystatus?vpnTunnelId=${vpnProfile._docId}`);
        const result = await response.data;
        setOpen(true);
        setMessage(result);
    }

    const handleDownload = async () => {
        const fileName = '236bae9f-722f-4f0e-b030-4f5c24d99a81.ovpn';
        // const clientId = '236bae9f-722f-4f0e-b030-4f5c24d99a81';
        try {
            const response = await axios.get(`api/vpn/downloadvpnfile?vpnTunnelId=${vpnProfile._docId}`);
            const data = await response.data;
            const result = data.data;

            if (result.url) {
                // Create a temporary anchor element
                const a = document.createElement("a");
                a.href = result.url;
                a.download = fileName; // Suggested filename
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                alert("Failed to get download link");
            }
        } catch (error) {
            console.error("Download error:", error);
            alert("Error downloading file");
        }
    };

    const getData = async () => {
        setLoading(true);
        const response = await axios.get(`api/vpn/${vpnProfile._docId} `);
        const result = await response.data;
        setProfile(result.data);
        setLoading(false);
    }


    useEffect(() => {
        getData();
    }, [open])

    const handleCloseSnackBar = () => {
        setOpen(false);
    }

    const handleDeleteClose = () => {
        onDelete(vpnProfile._docId);
    }

    useEffect(() => {
        if (!_.isEmpty(message) && !open) refresh();
    }, [message, open]);

    return (
        <Box >
            <Card sx={{
                position: 'relative',
                backgroundImage: 'linear-gradient(to bottom left, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)',
                color: 'white',
                borderRadius: '15px'
            }} elevation={3}>

                <CardContent sx={{ pt: 4, backgroundColor: 'rgba(255,255,255,0.2)' }}>
                    <Grid container spacing={3}>

                        <Grid item xs={2.5} >
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="subtitle1" >Tunnel Name</Typography>
                                    <Typography variant="caption" >{_.toUpper(vpnProfile.name)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="subtitle1" >Organization Name</Typography>
                                    <Typography variant="caption" >{_.toUpper(vpnProfile.organizationName)}</Typography>
                                    <Typography variant="caption" >Level : {vpnProfile.level}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Box>
                                    <Typography variant="subtitle1" >Tunnel Status</Typography>
                                    <Typography variant="caption" >{vpnProfile.isActive ? 'Running' : 'Not Running'}</Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item xs={2} >
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="subtitle1" >VPN IP</Typography>
                                    <Typography variant="caption" >{vpnProfile.vpn_ipv4}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Box>
                                    <Typography variant="subtitle1" >VPN Port</Typography>
                                    <Typography variant="caption" >{vpnProfile.vpn_port}</Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid item xs={2.5} >
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="subtitle1" >Users</Typography>
                                    <Typography variant="caption" >{vpnProfile.users.length}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Box>
                                    <Typography variant="subtitle1" >Total Devices</Typography>
                                    <Typography variant="caption" >{vpnProfile.devices.length}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {userNames.includes(_.toLower(user.name)) ?
                                    <Link href="https://openvpn.net/client/" target="_blank" underline="hover" sx={{ color: 'white', display: 'flex', gap: 2, mt: 5 }}>
                                        <Typography>VPN Client</Typography>
                                        <LaunchIcon fontSize="small" />
                                    </Link>
                                    : null}
                            </Grid>
                        </Grid>

                        <Grid item xs={2.5} >
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="subtitle1" >Created By</Typography>
                                    <Typography variant="caption" >{_.toUpper(vpnProfile.meta.createdBy)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Box>
                                    <Typography variant="subtitle1" >Created On</Typography>
                                    <Typography variant="caption" >{dayjs(vpnProfile.meta.createdAt).format('lll')}</Typography>
                                </Box>
                            </Grid>

                            {userNames.includes(_.toLower(user.name)) ?
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Button size="small" color='info' variant="contained" onClick={handleDownload} sx={{ my: 2 }}  >
                                        Download VPN File
                                    </Button>

                                </Grid>

                                : null}
                        </Grid>

                        <Grid item xs={2} >
                            {vpnStartStopPermission ?
                                <Grid item xs={12}>
                                    {/* <Tooltip title={vpnProfile.isActive ? "You need to Enable the tunnel first" : null} placement="right"> */}
                                    <Button
                                        size="small"
                                        variant="contained"
                                        onClick={handleStartStop}
                                        disabled={vpnProfile.isEnabled ? false : true}
                                        color={vpnProfile.isActive ? 'warning' : 'success'}
                                        sx={{ my: 2 }}>
                                        {vpnProfile.isActive ? 'Stop' : 'Start'}
                                    </Button>
                                    {/* </Tooltip> */}
                                </Grid>
                                : null}
                            {vpnEnableDisablePermission ?
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    {/* <Tooltip title={vpnProfile.isActive ? "You need to stop the tunnel first" : null} placement="right"> */}
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={handleEnableDisable}
                                        disabled={vpnProfile.isActive ? true : false}
                                        sx={{ my: 2, color: 'white', borderColor: 'white' }}>
                                        {vpnProfile.isEnabled ? 'Disable' : 'Enable'}
                                    </Button>
                                    {/* </Tooltip> */}
                                </Grid>
                                : null}
                            {vpnDeletePermission ?
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    {/* <Tooltip title={vpnProfile.isEnabled ? "You need to disable the tunnel first" : null} placement="right"> */}
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={handleDeleteClose}
                                        sx={{ my: 2 }}
                                        color="error"
                                        disabled={!vpnProfile.isActive && !vpnProfile.isEnabled ? false : true}>
                                        Delete
                                    </Button>
                                    {/* </Tooltip> */}
                                </Grid>
                                : null}

                        </Grid>

                        <Grid item xs={2}>
                            <Tooltip title="Refresh">
                                <IconButton aria-label="refresh" onClick={refresh} sx={{ position: 'absolute', top: 36, right: 22, color: 'white' }}>
                                    <SyncIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleCloseSnackBar}
                autoHideDuration={1500}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleCloseSnackBar}>
                    {_.has(message, 'data') ? message.data.message : '-'}
                </Alert>
            </Snackbar>
        </Box>
    )
}