import Chart from "react-apexcharts";

export default function DeviceStatusPieChart({ options, series, status }) {
    return (
        <div>
            <div id="chart">
                <Chart options={options} series={series} type="pie" height={380} width={'360'} />
            </div>
            <div id="html-dist">{status}</div>
        </div>
    );
}