import _ from 'lodash';
import dayjs from 'dayjs';
// Mui
import { Box, Typography, CardContent, LinearProgress, Tooltip, Card } from "@mui/material";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
// Custom Components
import StyledCard from "../../components/Card/StyledCard.component";
import Loading from '../../components/Common/Loading.Component';


import Radialbar from '../Charts/Radialbar.layout';

const ResourceCard = ({ profile, loading }) => {
    const cpu = (_.has(profile, 'utilization.cpu')) ? profile.utilization.cpu : '0';
    const ram = (_.has(profile, 'utilization.ram')) ? profile.utilization.ram : '0';
    const lastUpdated = (_.has(profile, 'utilization')) ? profile.utilization.timestamp : '0';
    // const flash = 0;
    const internalStorage = (_.has(profile, 'utilization.storage')) ? profile.utilization.storage : '0';

    function bytesToMB(bytes) {
        return parseFloat((bytes / (1024 * 1024)).toFixed(2));
    }
    // function bytesToGB(bytes) {
    //     return bytes / (1024 * 1024 * 1024);
    // }

    return (
        <StyledCard>
            <CardContent >

                <Typography variant="h5" color='primary.dark' gutterBottom>
                    Resource Utilization
                </Typography>

                <Box sx={{ display: 'flex', height: 400 }}>




                    <Card sx={{ width: '50%', pb: 10, position: 'relative' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: '10px' }}>
                            {loading ? <Loading /> :
                                (_.has(profile, 'utilization')) ?
                                    <Radialbar cpu={cpu.avg} height={'200'} /> : <Typography>Data Not Available</Typography>}
                            {
                                loading ? <Loading /> :
                                    (_.has(profile, 'utilization')) ?
                                        [...Array(parseInt(cpu.totalCores))].map((_, index) => (
                                            <Box key={index}>
                                                <Typography variant="subtitle1" sx={{ mt: '10px', color: '#707070' }}>Core {index + 1} : {`${cpu[`core${index + 1}`]}%`}</Typography>
                                                <LinearProgress sx={{ width: 320 }} variant="determinate" value={Number(cpu[`core${index + 1}`])} />
                                            </Box>
                                        )) : null
                            }
                        </Box>
                        {_.has(profile, 'utilization') ?
                            <Box sx={{ color: 'white', display: 'flex', flexDirection: 'column', px: 1, borderRadius: 1, position: 'absolute', right: 8, bottom: 8, backgroundColor: '#B2B2B2' }}>
                                <Typography variant="caption">{`last updated on ${dayjs(lastUpdated).format('DD MMMM, YYYY h:mm:ss A')}`}</Typography>
                            </Box>
                            : null}
                    </Card>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between', ml: '20px', width: '50%' }}>



                        <Card sx={{ position: 'relative' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 190 }}>
                                {loading ? <Loading /> :
                                    (_.has(profile, 'utilization')) ?
                                        <Radialbar ram={ram.usedPercent} height={'190'} /> : <Typography sx={{ position: 'absolute', top: 20, left: 20 }}>Data Not Available</Typography>}
                                {loading ? <Loading /> :
                                    (_.has(profile, 'utilization')) ?
                                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: '40px' }}>

                                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>
                                                Used: {bytesToMB(ram.used)} MB
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{ mt: '10px', color: '#808080' }}>
                                                Free: {bytesToMB(ram.free)} MB
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{ mt: '10px', color: '#808080' }}>
                                                Total: {bytesToMB(ram.total)} MB
                                            </Typography>
                                        </Box> : null}
                            </Box>
                            {_.has(profile, 'utilization') ?
                                <Box sx={{ color: 'white', display: 'flex', flexDirection: 'column', px: 1, borderRadius: 1, position: 'absolute', right: 8, bottom: 8, backgroundColor: '#B2B2B2' }}>
                                    <Typography variant="caption">{`last updated on ${dayjs(lastUpdated).format('DD MMMM, YYYY h:mm:ss A')}`}</Typography>
                                </Box>
                                : null}
                        </Card>




                        <Card sx={{ position: 'relative' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 190 }} >
                                {loading ? <Loading /> :
                                    (_.has(profile, 'utilization')) ?
                                        <Radialbar internalStorage={internalStorage.usedPercent} height={'190'} /> : <Typography sx={{ position: 'absolute', top: 20, left: 20 }}>Data Not Available</Typography>}
                                {loading ? <Loading /> :
                                    (_.has(profile, 'utilization')) ?
                                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: '40px' }}>
                                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>
                                                Used: {bytesToMB(internalStorage.used)} MB
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{ mt: '10px', color: '#808080' }}>
                                                Free: {bytesToMB(internalStorage.free)} MB
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{ mt: '10px', color: '#808080' }}>
                                                Total: {bytesToMB(internalStorage.total)} MB
                                            </Typography>
                                        </Box> : null}
                            </Box>
                            {_.has(profile, 'utilization') ?
                                <Box sx={{ color: 'white', display: 'flex', flexDirection: 'column', px: 1, borderRadius: 1, position: 'absolute', right: 8, bottom: 8, backgroundColor: '#B2B2B2' }}>
                                    <Typography variant="caption">{`last updated on ${dayjs(lastUpdated).format('DD MMMM, YYYY h:mm:ss A')}`}</Typography>
                                </Box>
                                : null}
                        </Card>



                    </Box>

                    {/* <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" sx={{ color: '#707070' }}>
                        Flash:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mt: '10px', color: '#808080' }}>
                        {`${flash} %`}
                    </Typography>
                    </Box>
                    <LinearProgress variant="determinate" value={flash} /> */}
                </Box>
            </CardContent>
        </StyledCard>
    );
}
export default ResourceCard;