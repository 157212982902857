import { useState, useEffect } from "react"
import axios from 'axios';
import _ from "lodash";
import { getPermissionObject } from "../../helper/Utility.helper";
import { useParams } from "react-router-dom";

// MUI Components
import { Box, Tab, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { TabPanel, TabContext, TabList } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';

// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import Overview from "../../layouts/DeviceDetail/Overview.layout";
// import DeviceLocation from '../../layouts/DeviceDetail/DeviceLocation.layout';
// import DeviceRemotes from "../../layouts/DeviceDetail/DeviceRemotes.layout";
// import Metrics from "../../layouts/DeviceDetail/Metrics.layout";
import { StyledBox, StyledText } from "../../layouts/DeviceDetail/StyleBox";
import EditDeviceNameDialog from "../../layouts/DeviceDetail/EditDeviceNameDialog.layout";
// import EditDeviceOrganizationDialog from "../../layouts/DeviceDetail/EditDeviceOrganizationDialog.layout";
// import Loading from "../../components/Common/Loading.Component"
import SyncIcon from '@mui/icons-material/Sync';


// const StyledTabs = styled(Tabs)({
//     marginTop: 'auto',
//     backgroundColor: '#fff',
// });
// function TabPanel(props) {
//     const { children, value, index, ...other } = props;
//     console.log('tab props', props);
//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box sx={{ p: 3 }}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }
// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.string.isRequired,
//     value: PropTypes.string.isRequired,
// };

export default function DeviceProfile() {

    const { serial } = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState('overview');
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(false);
    const [openEditDeviceNameDialog, setOpenEditDeviceNameDialog] = useState(false);
    // const [openEditDeviceOrganizationDialog, setOpenEditDeviceOeganizationDialog] = useState(false);

    //permission decoding
    const permissionObject = getPermissionObject();
    const devicesEditPermission = permissionObject.devices.update;

    //Handle for Device Name Change
    const handleOpenEditDeviceNameChange = () => {
        setOpenEditDeviceNameDialog(true);
    }
    const handleCloseEditDeviceNameChange = () => {
        setOpenEditDeviceNameDialog(false);
        handleResetAnchorEl();
        // getData();
    };

    //Handle for Device Organization Change
    // const handleOpenEditDeviceOrganizationChange = () => {
    //     setOpenEditDeviceOeganizationDialog(true);
    // }
    // const handleCloseEditDeviceOrganizationChange = () => {
    //     setOpenEditDeviceOeganizationDialog(false);
    //     handleResetAnchorEl();
    //     getData();
    // };

    const getData = async () => {
        setLoading(true);
        const response = await axios.get('api/devices/getprofile', { params: { serial } });
        const result = await response.data;
        setProfile(result.data);
        setLoading(false);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleAnchorEl = (e) => setAnchorEl(e.currentTarget);
    const handleResetAnchorEl = () => setAnchorEl(null)
    const openMenu = Boolean(anchorEl);

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Box>
                <SectionHeader heading="Devices" />
                <Box>
                    <StyledBox>
                        {devicesEditPermission ?
                            <>
                                <Menu
                                    id="basic-information-menu"
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleResetAnchorEl}
                                >
                                    <MenuItem dense onClick={handleOpenEditDeviceNameChange}>Change Device Name</MenuItem>
                                    {/* <MenuItem dense onClick={handleOpenEditDeviceOrganizationChange}>Change Organization</MenuItem> */}
                                </Menu>
                                <Tooltip title="Edit">
                                    <IconButton sx={{ position: 'absolute', right: 76, color: 'white' }}
                                        onClick={handleAnchorEl}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </> : null}
                        <Tooltip title="Refresh">
                            <IconButton aria-label="refresh" onClick={getData} sx={{ position: 'absolute', right: 22, color: 'white' }}>
                                <SyncIcon />
                            </IconButton>
                        </Tooltip>




                        {/* <IconButton sx={{ position: 'absolute', right: 12, color: 'white' }}
                            onClick={handleOpenEditDeviceNameChange}
                        >
                            <EditIcon />
                        </IconButton> */}
                        {/* <Avatar sx={{ width: 128, height: 128 }} src="/path/to/profile/avatar.jpg" alt="Profile Avatar" /> */}
                        <StyledText variant="h4" sx={{ color: '#fff', marginTop: 2 }}>
                            {
                                (!_.isEmpty(profile)) ?
                                    // _.get(profile, "profile.name", "-")
                                    _.toUpper(profile.name)
                                    : "-"}
                            {/* <IconButton sx={{ color: 'white' }}>
                            <EditIcon />
                        </IconButton> */}
                        </StyledText>
                        <StyledText variant="subtitle2" sx={{ color: '#ECEFF1', marginTop: 1 }}>
                            {
                                (!_.isEmpty(profile)) ?
                                    // _.get(profile, "profile.name", "-")
                                    _.toUpper(profile.organizationName)
                                    : "-"
                            }
                        </StyledText>

                    </StyledBox>
                    <Box sx={{ marginTop: 'auto', width: '100%' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList
                                    onChange={handleChange}
                                    aria-label="device profile page tabs"
                                    centered
                                >
                                    <Tab label="Overview" value='overview' />
                                    {/* {
                                        (_.has(profile, 'location') && !_.isEmpty(profile.location)) ?
                                            <Tab label="Location" value='location' /> : null
                                    } */}
                                    {/* <Tab label="Metrics" value='metrics' /> */}
                                    {/* <Tab label="Analysis" value='history' /> */}
                                    {/* <Tab label="Remote Connections" value='remote-connections' /> */}
                                    {/* <Tab label="Remote(s) HTTP" value='remotes-http' /> */}
                                    {/* <Tab label="Remote(s) SSH" value='rsemotes-ssh' /> */}

                                </TabList>

                            </Box>

                            <TabPanel value={'overview'}>
                                <Overview profile={profile} loading={loading} />
                            </TabPanel>
                            {/* {
                                (_.has(profile, 'location') && !_.isEmpty(profile.location)) ?
                                    <TabPanel value={'location'}>
                                        <DeviceLocation data={profile} loading={loading} />
                                    </TabPanel>
                                    : null
                            } */}
                            {/* <TabPanel value={'metrics'}>
                            <Metrics serial={serial} />
                        </TabPanel> */}
                            {/* <TabPanel value={'metrics'}>
                                <Metrics serial={serial} loading={loading} />
                            </TabPanel> */}
                            {/* <TabPanel value={'remote-connections'}>
                            <DeviceRemotes />
                        </TabPanel> */}
                        </TabContext>
                    </Box>
                </Box>
            </Box>

            {loading ? null :
                <EditDeviceNameDialog
                    openDialog={openEditDeviceNameDialog}
                    handleClose={handleCloseEditDeviceNameChange}
                    name={_.has(profile, "name") ? profile.name : '-'}
                    serial={_.has(profile, "serial") ? profile.serial : '-'}
                    refresh={getData}
                />
            }
            {/* {loading ? null :
                <EditDeviceOrganizationDialog
                    openDialog={openEditDeviceOrganizationDialog}
                    handleClose={handleCloseEditDeviceOrganizationChange}
                    name={_.has(profile, "organizationName") ? profile.organizationId : '-'}
                    serial={_.has(profile, "serial") ? profile.serial : '-'}
                />
            } */}
        </>
    );
}