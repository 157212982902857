import React, { Component } from "react";
import Chart from "react-apexcharts";
class DeviceCountColumnChart extends React.Component {

    render() {
        return (
            <div id="chart" style={{ maxHeight: '480px' }}>
                <Chart options={this.props.options} series={this.props.series} type="bar" height={'455'} width={'100%'} />
            </div>
        );
    }
}

export default DeviceCountColumnChart;