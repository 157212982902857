// MUI Components
import { IconButton, Grid, Button } from '@mui/material';
// Material Icons
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";

import Search from '../../components/Search/Search.component';

const actions = ({ handleQueryInput, getData }) => (
    <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignContent='center'

    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id='search-roles' handleChange={handleQueryInput} placeholder='Search role...' />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button disabled variant="contained" size="small" sx={{ float: 'right' }} endIcon={<FilterAltIcon />}>Filter</Button>
        </Grid>
    </Grid>
);
const contextActions = deleteHandler => (
    <IconButton onClick={deleteHandler}>
        <DeleteIcon />
    </IconButton>
)

const CreateRole = ({ handleNavigate }) => (
    <Button
        variant="contained"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleNavigate}
    >Create Role</Button>
)

export { CreateRole, contextActions, actions }