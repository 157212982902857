import { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash"
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { Box, Container, Snackbar, Button, TextField, Stack, Dialog, DialogActions, DialogContent, DialogTitle, Alert } from "@mui/material";
import { BoxItem } from '../../components/Common/Item.component';

export default function EditDeviceNameDialog({
    openDialog, handleClose, name, serial, refresh
}) {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    const initialValues = {
        name: name,
        serial: serial
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[a-zA-Z0-9\s_#-]+$/, "Enter valid name")
            .trim()
            .required('Please Enter Model Name'),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (data) => {
            // alert(JSON.stringify(data, null, 2));
            // console.log(JSON.stringify(data, null, 2));
            putData(data);
            formik.values.name = "";
        }
    })

    const putData = async (data) => {
        const response = await axios.put('/api/devices/updatedevicename', data);
        const result = await response.data;
        handleClose()
        setOpen(true);
        setMessage(result);
        if (result.status) {
            setTimeout(() => {
                navigate(`/devices/${serial}`, { replace: true });
            }, 4000);
        }
    }

    const handleCloseSnackBar = () => {
        setOpen(false);
    }

    useEffect(() => {
        if (!_.isEmpty(message) && !open) refresh();
    }, [message, open]);

    return (
        <Box>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
            >
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Change Device Name</DialogTitle>
                    <DialogContent>
                        <Container maxWidth="sm">
                            <Stack
                                sx={{ mt: 1 }}
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                spacing={3}
                            >
                                <BoxItem>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        // required
                                        size="small"
                                        margin="normal"
                                        id="name"
                                        name="name"
                                        label="Name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </BoxItem>
                            </Stack>
                        </Container>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">Cancel</Button>
                        <Button
                            type="submit"
                            variant="contained"
                        >
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleCloseSnackBar}
                autoHideDuration={2250}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleCloseSnackBar}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    );
}