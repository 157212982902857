import * as React from "react";
// MUI Components
import { Box, Typography, Breadcrumbs, Link, Divider, IconButton, Tooltip } from "@mui/material";
import { useLocation, Link as RouterLink } from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Event function for "Breadcrumbs";
const handleClick = event => {
    event.preventDefault();
    // console.log("You clicked a breadcrumb.");
}

// Section Header a resuable component
const SectionHeader = (props) => {

    let location = useLocation();
    let pathnames = location.pathname.split("/").filter((x) => x);

    return (

        <Box component="div">
            {/* A section name */}
            <Box sx={{ display: 'flex' }}>
                < Typography variant='h4' >
                    {
                        props.heading ? props.heading :
                            'Section Heading'
                    }
                </Typography >

                {
                    props.iconButton ?
                        <Link target='_blank' href="https://docs.atreyo.in/" underline="none">
                            <HelpOutlineIcon fontSize="small" />
                        </Link>
                        : null
                }
            </Box>






            <Box component="div">
                {
                    pathnames.length != 1 ?
                        // false ?
                        <div role="presentation" onClick={handleClick}>
                            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
                                {
                                    pathnames.map((p, i) => {
                                        const last = i === pathnames.length - 1
                                        const to = `/${pathnames.slice(0, i + 1).join('/')}`
                                        return last ?
                                            // <Typography color="text.primary" key={p}>{p.toUpperCase()}</Typography>
                                            null
                                            :
                                            <Link component={RouterLink} underline="hover" color="inherit" to={to} key={p} >
                                                {/* {p.toUpperCase() +  */}

                                                {/* } */}
                                                <Tooltip title="Back" placement="right">
                                                    <ArrowBackIcon />
                                                </Tooltip>
                                                {/* BACK */}
                                            </Link>
                                    })
                                }

                            </Breadcrumbs>
                        </div> : null
                }
                <Divider />
            </Box>
        </Box >
    );
};

export default SectionHeader;