import axios from "axios";
import { useState, useEffect } from "react";
import DeviceStatusPieChart from "../../layouts/Charts/DeviceStatusPieChart.layout";
import Loading from "../../components/Common/Loading.Component"

export default function OnlineStatusPieChart() {
    const [isData, setIsData] = useState(false);
    const [loading, setLoading] = useState(false);

    const [state, setState] = useState({
        series: [],
        options: {
            chart: {
                width: 'auto',
                type: 'pie',
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    });

    const getData = () => {
        setLoading(true);
        axios.get('api/overview/onlinecountbymodel')
            .then(response => {
                const data = response.data;

                if (data.status) {
                    setIsData(true);

                    const newOptions = {
                        ...state,
                        series: data.data.map(c => parseInt(c.count))
                    }
                    newOptions['options']['labels'] = data.data.map(m => m.model.toUpperCase())
                    setState(newOptions);
                }
                setLoading(false);
            }).catch(err => {
                console.log('An error occurred while fetching data: ', err);
            }).finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <div>
            {loading ? <Loading /> : isData && state.series.length > 0 && state.options.labels.length > 0 ?
                <DeviceStatusPieChart options={state.options} series={state.series} />
                : 'Data Not Available'}
        </div>
    )
}