import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import _ from 'lodash';
import dayjs from "dayjs";
import { getPermissionObject } from "../../helper/Utility.helper";

// MUI Components
import { Box, Typography, Container } from "@mui/material";

// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import DataTable from '../../components/DataTable/DataTable.component';
import NavigationComponent2 from "../../components/Common/NavigateComponent2.component";
import { actions, AddVPNTunnel } from "./Actions";
import { SearchQuery } from "../../components/Search/SearchQuery";

export default function VPNList() {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [queryInput, setQueryInput] = useState("");

    //permission decoding
    const permissionObject = getPermissionObject();
    const vpnCreatePermission = permissionObject.vpn.create;

    const columns = [
        {
            id: 1,
            name: 'Tunnel Name',
            compact: true,
            cell: row => <NavigationComponent2 id={row._docId} name={_.toUpper(row.name)} />,
        },
        {
            id: 2,
            name: 'Total Users',
            compact: true,
            selector: row => row['totalUser'],
        },
        {
            id: 3,
            name: 'Total Devices',
            compact: true,
            selector: row => row['totalDevices'],
        },
        {
            id: 4,
            name: 'Organization Name',
            sortable: true,
            cell: row =>
                <Box>
                    <Box >
                        <Typography>{_.toUpper(row['organizationName'])}</Typography>
                    </Box>
                    <Box >
                        <Typography variant="caption">{`Level : ${row['level']}`}</Typography>
                    </Box>
                </Box>
        },
        {
            id: 5,
            name: 'Created By',
            selector: row => (_.toUpper(row['createdBy'])),
            sortable: true
        },
        {
            id: 6,
            name: 'Created On',
            selector: row => row['createdAt'],
            sortable: true,
            cell: row => dayjs(row['createdAt']).format('lll')
        },
    ]

    const getStoredRowsPerPage = () => {
        return Number(sessionStorage.getItem("vpnTableRows")) || 5;
    };

    const [rowsPerPage, setRowsPerPage] = useState(getStoredRowsPerPage);

    // Store rows per page value in session storage when it changes
    const handlePerRowsChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        sessionStorage.setItem("vpnTableRows", newRowsPerPage);
    };

    const handleNavigate = () => navigate('create-vpn-tunnel');

    const handleQueryInput = (event) => {
        let query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }

    const getData = async () => {
        setLoading(true);
        const response = await axios.get('api/vpn');
        const data = await response.data;
        setData(data.data);
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Box>
            <SectionHeader heading="VPN" />

            <Box sx={{ flexGrow: 1, my: 2 }}>
                <Container>
                    <DataTable
                        title={vpnCreatePermission ? <AddVPNTunnel handleNavigate={handleNavigate} /> : null}
                        columns={columns}
                        data={SearchQuery(queryInput, data, ['name', 'organizationName'])}
                        defaultSortField="tunnleName"
                        defaultSortFieldId={1}
                        actions={actions({ handleNavigate, handleQueryInput, getData })}
                        // contextActions={contextActions(deleteAll)}
                        // onSelectedRowsChange={handleChange}
                        // clearSelectedRows={toggleCleared}
                        progressPending={loading}
                        selectableRows={false}
                        selectableRowsHighlight={false}
                        paginationPerPage={rowsPerPage}
                        onChangeRowsPerPage={handlePerRowsChange}
                    />
                </Container>
            </Box>
        </Box >
    )
}