import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useCopyToClipboard } from "@uidotdev/usehooks";
import { getPermissionObject } from "../../helper/Utility.helper";
import { SearchQuery } from "../../components/Search/SearchQuery";

// MUI Components
import { Box, Container, Chip, Typography, IconButton, Tooltip } from "@mui/material";

// Icons
import { FaCircle } from "react-icons/fa";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import DataTable from '../../components/DataTable/DataTable.component';
import NavigationComponent2 from "../../components/Common/NavigateComponent2.component";
import { AddDevice, contextActions, actions } from "./Actions"



export default function DeviceList(props) {
    dayjs.extend(relativeTime);
    dayjs.extend(localizedFormat);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [queryInput, setQueryInput] = useState("");
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [copiedText, copyToClipboard] = useCopyToClipboard();
    const [tooltipTitle, setTooltipTitle] = useState('');

    //permission decoding
    const permissionObject = getPermissionObject();
    const devicesAddPermission = permissionObject.devices.create;


    const columns = [
        {
            id: 1,
            name: 'Name',
            selector: row => row['name'],
            sortable: true,
            cell: row => <NavigationComponent2 id={row.serial} name={_.toUpper(row.name)} />,
        },
        {
            id: 2,
            name: 'Serial',
            width: '200px',
            cell: row =>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ width: "140px", fontSize: 14 }}>{row['serial']}</Typography>
                    <Tooltip title={tooltipTitle} placement="right">
                        <IconButton onClick={() => {
                            copyToClipboard(row['serial'])
                            setTooltipTitle('Copied');
                            setTimeout(() => {
                                setTooltipTitle('');
                            }, 500);
                        }} size="small">
                            <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Box>,
            sortable: true,
        },
        {
            id: 3,
            name: 'Model',
            selector: row => row['model'],
            sortable: true,
            width: '150px'
        },
        {
            id: 4,
            name: 'Status',
            compact: true,
            width: '100px',
            selector: row => row['connectionStatus'],
            cell: row => (row['connectionStatus'] === "connected") ?
                <Chip icon={<FaCircle size="16px" />} label="Online" color="success" size="small" variant="outlined" />
                :
                <Chip icon={<FaCircle size="16px" />} label="Offline" color="error" size="small" variant="outlined" />
        },
        {
            id: 5,
            name: 'Organization Name',
            sortable: true,
            cell: row =>
                <Box>
                    <Box >
                        <Typography>{_.toUpper(row['organizationName'])}</Typography>
                    </Box>
                    <Box >
                        <Typography variant="caption">{`Level : ${row['level']}`}</Typography>
                    </Box>
                </Box>
        },
        {
            id: 6,
            name: 'Connection Updated',
            selector: row => row['connectionModified'],
            sortable: true,
            cell: row => row['connectionModified'] === 0 ? "-" : dayjs(row['connectionModified']).fromNow()
        },
        {
            id: 7,
            name: 'Created On',
            selector: row => row['createdOn'],
            sortable: true,
            cell: row => dayjs(row['createdOn']).format('lll')
        },
    ];

    const getStoredRowsPerPage = () => {
        return Number(sessionStorage.getItem("deviceTableRows")) || 5;
    };

    const [rowsPerPage, setRowsPerPage] = useState(getStoredRowsPerPage);

    // Store rows per page value in session storage when it changes
    const handlePerRowsChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        sessionStorage.setItem("deviceTableRows", newRowsPerPage);
    };

    const deleteAll = () => {
        const rows = selectedRows.map(r => r.serial);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, rows, 'serial'));
        }
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    const handleNavigate = () => navigate('add-devices');

    const handleQueryInput = (event) => {
        let query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }

    const getData = async () => {
        setLoading(true);
        const response = await axios.get('api/devices/getalldevices');
        const data = await response.data;
        setData(data.data);
        setLoading(false);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Box>
            {/* Sectoin Header */}
            <SectionHeader heading="Devices" />

            <Box sx={{ flexGrow: 1, my: 2 }}>
                <Container>
                    <DataTable
                        title={devicesAddPermission ? <AddDevice handleNavigate={handleNavigate} /> : null}
                        columns={columns}
                        data={SearchQuery(queryInput, data, ['name', 'serial', 'model', 'organizationName'])}
                        defaultSortField="serial"
                        defaultSortFieldId={4}
                        actions={actions({ handleNavigate, handleQueryInput, getData })}
                        contextActions={contextActions(deleteAll)}
                        onSelectedRowsChange={handleChange}
                        clearSelectedRows={toggleCleared}
                        progressPending={loading}
                        selectableRows={false}
                        selectableRowsHighlight={false}
                        paginationPerPage={rowsPerPage}
                        onChangeRowsPerPage={handlePerRowsChange}
                    />
                </Container>
            </Box>
        </Box >
    );
}