import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash"
import axios from "axios";
import { useSelector } from "react-redux";

// Mui
import {
    Box, Dialog, Grid, Divider, DialogTitle, FormControl
    , TextField, Button, Snackbar, Alert
} from "@mui/material";

// Custom Components
import PermissionCard from "../../layouts/Roles/PermissionCard.layout";

export default function EditRole({
    openDialog, handleClose, rName, id, permissionById, refresh
}) {

    const navigate = useNavigate();
    const permissionObject = permissionById;
    const { remote, ...updatedpermissionObject } = permissionObject;
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [name, setName] = useState(rName);
    const [permissions, setPermissions] = useState(updatedpermissionObject);
    const user = useSelector(state => state.authenticate.user);

    const handleEvent = (e, groupKey) => {
        const { name, checked } = e.target;
        setPermissions((prev) => {
            let updatedGroup = { ...prev[groupKey], [name]: checked };
            if (name !== "view" && checked) updatedGroup.view = true;
            if (name === "view" && !checked) Object.keys(updatedGroup).forEach((key) => (updatedGroup[key] = false));

            let updatedPermission = { ...prev, [groupKey]: updatedGroup };
            if ((groupKey === "remote" || groupKey === "vpn") && checked) updatedPermission.devices.view = true;
            if (groupKey === "devices" && name === "view" && !checked) {
                ["remote", "vpn"].forEach((key) => Object.keys(updatedPermission[key] || {}).forEach((k) => (updatedPermission[key][k] = false)));
            }
            return updatedPermission;
        });
    };

    const handleRoleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleCloseSnackbar = () => {
        setOpen(false);
    }

    const handleReset = () => {
        setName(rName);
        setPermissions(updatedpermissionObject);
    }

    const handleCancel = () => {
        navigate("/roles");
    };

    const handleSubmit = () => {
        const formData = { permissions, name }
        // console.log(formData);
        putData(formData)
    };

    const putData = async (data) => {
        const response = await axios.put(`/api/roles/${id}`, data);
        const result = await response.data;
        // console.log('Install response', response.status);
        handleClose();
        setOpen(true);
        setMessage(result);

    }

    useEffect(() => {
        if (!_.isEmpty(message) && !open) refresh();
    }, [message, open]);

    return (
        <Box >
            <Dialog
                open={openDialog}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                sx={{ p: 4 }}
            >
                <DialogTitle>Edit Permissions</DialogTitle>

                <Grid container sx={{ px: 4 }} spacing={1}>
                    <Grid item xs={12} >
                        <FormControl margin="normal">
                            <TextField
                                size="small"
                                label="Role Name"
                                name="name"
                                value={name}
                                onChange={handleRoleNameChange}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} container spacing={1} sx={{ mt: 2, width: '100%', height: 'calc(100vh - 360px)', overflowY: 'scroll' }}>
                        {Object.keys(permissions).filter((key) => (user.level === 3 ? key !== "organizations" : key)).map((key) => (
                            <Grid key={key} item xs={12} sm={12} md={6} lg={3}>
                                <PermissionCard
                                    key={key}
                                    title={key.charAt(0).toUpperCase() + key.slice(1)}
                                    permissions={permissions[key]}
                                    groupKey={key}
                                    handleEvent={handleEvent}
                                />
                            </Grid>
                        ))}

                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sx={{ width: '100%', my: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="outlined" onClick={handleReset}>
                            Reset
                        </Button>
                        <Button onClick={handleSubmit} variant="contained">
                            Save
                        </Button>
                        <Button variant="outlined" color="error" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>

            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleCloseSnackbar}
                autoHideDuration={2250}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleCloseSnackbar}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    );
}