import _ from "lodash";
import { store } from "../../index"  // Import your Redux store
import { createReducer } from "@reduxjs/toolkit";
import {
    loginRequest, loginSuccess, loginFailure, logoutRequest,
    updateSession
} from '../actions/login.action';
import { setTimeZone } from '../../helper/Utility.helper';
import { checkUserSession } from "../../services/session.service";
let INITIAL_STATE = {
    loading: false,
    user: null,
    _aid: null,
    error: null,
    isAuthenticated: false
};

// const currentUser = getCurrentUser();
checkUserSession().then(session => {
    // console.log("current user:", session);

    const newState = {
        isAuthenticated: true,
        isVerified: session.isVerified,
        _aid: localStorage.getItem("_aid"),
        user: JSON.parse(atob(localStorage.getItem("_info"))),
    };

    store.dispatch(updateSession(newState)); // ✅ Dispatch Redux action
    setTimeZone(session.zoneinfo);
})
    .catch(err => {
        // console.log("An error occurred while validating user session:", err);
    });
// console.log('after initial state:', INITIAL_STATE);

const loginReducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(updateSession, (state, action) => {
            return { ...state, ...action.payload }; // ✅ Update Redux state correctly
        })
        // .addCase(updateSession, (state, action) => {
        //     state.loading = false;
        //     state.error = null;
        //     state._aid = action.payload.accessToken;
        //     state.user = action.payload.user;
        //     state.isAuthenticated = true;
        //     state.isVerified = action.payload.user.isVerified;
        // })
        .addCase(loginRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(loginSuccess, (state, action) => {
            state.loading = false;
            state.error = null;
            state._aid = action.payload.accessToken;
            state.user = action.payload.user;
            state.isAuthenticated = true;
            state.isVerified = action.payload.user.isVerified;
        })
        .addCase(loginFailure, (state, action) => {
            state.loading = false;
            state.user = null;
            state.isAuthenticated = false;
            state.error = action.payload;
        })
        .addCase(logoutRequest, (state) => {
            localStorage.clear();
            state.loading = false;
            state.user = null;
            state.isAuthenticated = false;
            state.isVerified = false;
            state._aid = null;
            state.error = null;
        })
});

export default loginReducer;