import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Grid,
    TextField,
    Typography,
    Link,
    Alert
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';
import { styled } from '@mui/system';
import Copyright from '../../layouts/Authentication/Copyright.component';
import logo from '../../assets/ATRA_emblem.png';
import backgroundImage from '../../assets/sideBanner.jpg';
import { loginUser } from '../../services/login.service';

const BackgroundContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    display: 'block',
    overflow: 'hidden',
}));

const FormContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    height: '100vh',
    // padding: theme.spacing(2),
    // paddingTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ImageContainer = styled('div')(({ theme }) => ({
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'left',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Logo = styled('img')(({ theme }) => ({
    width: '6em',
    marginBottom: theme.spacing(3),
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
    background: `-webkit-linear-gradient(-65deg, #ABD9E6 5%, #48A89D 27%, #134E5E 95%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    paddingBottom: theme.spacing(6),
}));

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const error = useSelector(state => state.authenticate.error);
    const isLoading = useSelector(state => state.authenticate.loading);

    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: yup.object().shape({
            email: yup
                .string()
                .email('Enter a valid email')
                .required('Email is required'),
            password: yup
                .string()
                .min(8, 'Required minimum 8 characters length')
                .required('Password is required'),
        }),
        onSubmit: (formData) => {
            // console.log('Login Form data', formData);
            dispatch(loginUser(formData))
        }
    });

    return (
        <BackgroundContainer>
            {/*<Container maxWidth="lg">*/}
            <Grid container >
                <Grid item xs={12} md={12} lg={5} xl={5}>
                    <form onSubmit={formik.handleSubmit}>
                        <FormContainer>
                            <Logo src={logo} alt="Logo" />
                            <GradientTypography component="div" variant="h5" align="center">
                                {/* {'प्रवेशः।'} */}
                                {`Login`}
                            </GradientTypography>

                            <Box sx={{ width: '50%' }}>
                                <Box sx={{ mb: 3 }}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Email"
                                        id="email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && Boolean(formik.errors.email)}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                                <Box sx={{ mb: 3 }}>

                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        id="password"
                                        name="password"
                                        label="Password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        helperText={formik.touched.password && formik.errors.password}
                                        InputLabelProps={{ shrink: true }}

                                    />
                                </Box>
                            </Box>
                            <Box sx={{ mt: 0, width: '50%' }}>

                                <LoadingButton
                                    variant="contained"
                                    size="small"
                                    type="submit"
                                    sx={{
                                        mt: 4,
                                        width: '100%'
                                    }}
                                    endIcon={<LoginIcon />}
                                    loading={isLoading}
                                >
                                    Log in
                                </LoadingButton >
                            </Box>
                            <Grid container justifyContent="center" sx={{ mt: 2 }}>
                                <Grid item>
                                    <Link
                                        sx={{ cursor: 'pointer' }}
                                        underline="always"
                                        variant='body2'
                                        align='center'
                                        onClick={() => navigate('/forgot-password', { replace: true })}
                                    >
                                        Forgot password?
                                    </Link>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="center" sx={{ mt: 2 }}>
                                <Grid item>
                                    <Typography sx={{ cursor: 'pointer' }} variant="body2" align="center" color="textSecondary">

                                        <Link href={`https://atreyo.in/en/contact-us`} target='_blank' underline="always">
                                            Contact Us
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Copyright
                                sx={{ mt: 2 }}
                            />
                            {
                                (error && error.message !== "") ?
                                    <Grid container justifyContent="center" sx={{ mt: 2 }}>
                                        <Grid item>
                                            <Alert severity="error">
                                                {error.message}
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                    : null
                            }
                        </FormContainer>
                    </form>
                </Grid>
                <Grid item xs={12} md={7} sx={{ padding: 0, margin: '0px' }}>
                    <ImageContainer />
                </Grid>
            </Grid>
            {/*</Container>*/}
        </BackgroundContainer>
    );
};


export default Login;
